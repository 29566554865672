import React, { useEffect } from "react";
import { useTable, usePagination } from "react-table";
import createPagination from "../Hooks/usePagination";
import { MdCompareArrows } from "react-icons/md";

const CustomTable = ({
    columns,
    data,
    updatePagination,
    paginationState,
    pageCount: controlledPageCount,
    sortable,
    hidePagination,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        gotoPage,
        previousPage,
        nextPage,
        pageCount,
        canNextPage,
        canPreviousPage,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            pageCount: controlledPageCount,
            initialState: {
                pageIndex: 0,
            },
        },
        usePagination
    );

    useEffect(() => {
        if (updatePagination) {
            updatePagination((prev) => ({
                ...prev,
                // limit: pageSize,
                page: pageIndex + 1,
            }));
        }
    }, [updatePagination, pageIndex, pageSize]);

    const { pagination } = createPagination({
        numberOfButtons: 2,
        currentPage: paginationState ? paginationState.page : pageIndex + 1,
        totalPages: paginationState
            ? paginationState.totalPages
                ? paginationState.totalPages
                : controlledPageCount
            : controlledPageCount,
    });

    return (
        <div className="table-wrapper block max-w-full overflow-y-hidden ">
            <table className="text-white w-full borderorange border" {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    className="p-4 bg-black font-bold border-b borderorange text-white font-semibold text-xs min-w-ten"
                                    {...column.getHeaderProps()}
                                >
                                    <div
                                        className={`flex items-center justify-center`}
                                    >
                                        <div className="whitespace-no-wrap">
                                            {column.render("Header")}
                                        </div>
                                        {sortable && (
                                            <div className="cursor-pointer ml-2">
                                                {sortable.includes(
                                                    column.id
                                                ) && (
                                                    <MdCompareArrows
                                                        onClick={() => {
                                                            updatePagination(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    by:
                                                                        column.id,
                                                                    dir:
                                                                        prev.dir ===
                                                                        "asc"
                                                                            ? "desc"
                                                                            : "asc",
                                                                    page: 1
                                                                })
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                        className={`${
                                            row.original.status === "cancel"
                                                ? "bg-red-700 text-white"
                                                : row.original.status === "converted"
                                                ? "bg-yellow-300 text-black"
                                                : row.original.status === "signedLo"
                                                ? "bg-green-300 text-black"
                                                : row.original.status === "suspended"
                                                ? "bg-red-700 text-white"
                                                : row.original.status === "risksuspended"
                                                ? "bg-pink-600 text-white"
                                                : row.original.status === "void" || row.original.status === "reject"
                                                ? "bg-purple-500 text-white"
                                                : row.original.calculatePoints === "void"
                                                ? "bg-red-700 text-white"
                                                : "" // Default class if none of the conditions are met
                                        } bg-grey border-b border-t border-gray-400 p-6 text-sm text-black text-center font-semibold`}
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render("Cell")}
                                    </td>
                                    
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {!hidePagination && (
                <div className="p-4 border borderorange bg-black font-semibold text-white text-xs">
                    <ul className="flex justify-end pr-5">
                        <li
                            className="p-2 cursor-pointer"
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                            key="first"
                        >
                            First
                        </li>
                        <li
                            className="p-2 cursor-pointer"
                            onClick={() => previousPage()}
                            key="prev"
                        >
                            Previous
                        </li>
                        {pagination.map((page, i) => (
                            <li
                                className={`p-2 px-4 cursor-pointer ${
                                    page === pageIndex + 1
                                        ? "bg-black text-orange-400"
                                        : "text-xs"
                                }`}
                                key={i}
                                onClick={() => gotoPage(page - 1)}
                            >
                                {page}
                            </li>
                        ))}
                        <li
                            className="p-2 cursor-pointer"
                            onClick={() => nextPage()}
                            key="next"
                        >
                            Next
                        </li>
                        <li
                            className="p-2 cursor-pointer"
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                            key="last"
                        >
                            Last
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CustomTable;
