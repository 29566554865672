import React, { useEffect } from "react";
import queries from "../helpers/queries";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/react-hooks";
import { toast } from "react-toastify";
import axios from "axios";
import { FiX } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { withUser } from "../Contexts/user";

import { useApolloClient } from "@apollo/react-hooks";

const formFields = [
    {
        name: "name",
        label: "Full Name",
    },
    {
        name: "nickname",
        label: "Nickname",
    },
    {
        name: "phone",
        label: "Phone Number",
    },
    {
        name: "bankDetails",
        label: "Bank Details",
    },
];


const EditProfile = ({ user }) => {
    const modalData = user.auth;
    const { register, handleSubmit, errors, getValues, setValue } = useForm({
        defaultValues: {
            name: "",
            image: "",
            phone: "",
            bankDetails: "",
            nickname: "",
        },
    });

    const history = useHistory();
    const apollo = useApolloClient();

    const generateSignature = async () => {
        const ikSigReq = await apollo.query({
            query: queries.GENERATE_IK_SIGNATURE,
            fetchPolicy: "no-cache",
        });

        return ikSigReq;
    };

    useEffect(() => {
        const { name, nickname, phone, bankDetails } = modalData;

        const values = [{ name }, { nickname }, { phone }, { bankDetails }];
        setValue(values);
    }, [modalData, setValue]);

    const [editUserProfile] = useMutation(queries.EDIT_USER_PROFILE);

    const handleEditUserProfile = async () => {
        try {
            const formValues = getValues();

            let newImage = undefined;

            if (formValues.image.length > 0) {
                const imageresult = await generateSignature();
                const imageFormData = new FormData();

                imageFormData.append("file", formValues.image[0]);
                imageFormData.append(
                    "publicKey",
                    "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
                );
                imageFormData.append(
                    "signature",
                    imageresult.data.generateImageKitSignature.signature
                );
                imageFormData.append(
                    "expire",
                    imageresult.data.generateImageKitSignature.expire
                );
                imageFormData.append(
                    "token",
                    imageresult.data.generateImageKitSignature.token
                );
                imageFormData.append(
                    "fileName",
                    `${formValues.image[0].name} - ${imageresult.data.generateImageKitSignature.expire}`
                );
                imageFormData.append("folder", "users");

                const imageUploadResult = await axios.post(
                    "https://upload.imagekit.io/api/v1/files/upload",
                    imageFormData
                );

                newImage = imageUploadResult.data.url;
            }

            const result = await editUserProfile({
                variables: {
                    name: formValues.name,
                    nickname: formValues.nickname,
                    image: newImage ? newImage : modalData.image,
                    phone: formValues.phone,
                    bankDetails: formValues.bankDetails,
                },
            });

            if (result.error) {
                alert("Failed");
            }

            if (result.data) {
                // do success stuff
                toast("Your Profile has successfully been updated");
                history.push("/");
            }
        } catch (err) {
            //do err stuff
            alert("Failed");
            console.log(err);
        }
    };

    return (
        <div
            className="flex h-screen lg:h-full p-8"
            style={{ backgroundColor: "black" }}
        >
            <div className="container h-full mx-auto flex justify-center items-center">
                <div className="panel bg-white p-8 lg:p-16 rounded-md">
                    <div className="flex justify-end">
                        <Link to="/">
                            <FiX className="cursor-pointer" />
                        </Link>
                    </div>
                    <div className="mb-6 text-3xl font-bold">Edit Profile</div>
                    <form
                        onSubmit={handleSubmit(handleEditUserProfile)}
                        className="lg:w-104"
                    >
                        {formFields.map((item, i) => {
                            return (
                                <div key={item.name}>
                                    <div className="mb-2 text-sm text-lynch font-medium capitalize">
                                        {item.label}
                                    </div>
                                    <input
                                        className={`my-2 p-3 w-full capitalize rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                            errors[item.name]
                                                ? "border-red-400"
                                                : "border-gray-300"
                                        }`}
                                        name={item.name}
                                        ref={register({ required: false })}
                                        placeholder={item.label}
                                        type={item.type ? item.type : "text"}
                                    />
                                    {errors[item.name] && (
                                        <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                    )}
                                </div>
                            );
                        })}
                        <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default withUser(EditProfile);
