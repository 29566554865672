import React, { useState } from "react";
import queries from "../helpers/queries";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom";
import BannerOverlay from "../Components/BannerOverlay";
import {

    FcMinus,

} from "react-icons/fc";
import "../override.css";


const Saleskit = withRouter(() => {
    const locate = useLocation();
    const path = locate.pathname.split("/")[3];


    const [packages, setPackages] = useState(undefined);
    const [galleryModal, setGalleryModal] = useState(false);

    const [paginationState, setPaginationState] = useState({
        limit: 10,
        page: 1,
    });
    // eslint-disable-next-line no-unused-vars
    const [filters, setFilters] = useState({
        area: null,
        state: null,
    });


            useQuery(queries.FETCH_PROJECTS, {
                variables: {
                    limit: paginationState.limit,
                    page: paginationState.page,
                    area: filters.area ? filters.area : "",
                    state: filters.state ? filters.state : "",
                    project: path ? path : "",
                },
                fetchPolicy: "network-only",
                onCompleted: ({
                    fetchProjectsAgent: { pagination, ...fetchProjectsAgent },
                }) => {
                    setPaginationState(pagination);
                    setPackages(fetchProjectsAgent.data);
                },
                onError: ({ graphQLErrors, networkError }) => {
                    if (graphQLErrors) {
                        for (const graphQLError of graphQLErrors) {
                            console.log(graphQLError);
                        }
                    }
        
                    if (networkError) {
                        console.log(networkError);
                    }
                },
            });

    return (
        <div>
            <BannerOverlay
                imageUrl={packages ? packages[0].image : ""}
                title={packages ? packages[0].name : ""}
            ></BannerOverlay>
                    <div className="grid grid-cols-1 gap-4 mb-12  tnc-background ">
                        {packages
                            ? packages.map((item, i) => {
                                  return (
                                      <div
                                          className="block border greyOpaqueBackground border-black"
                                          key={i}
                                      >
                                          <div
                                              className="grid lg:grid-cols-1"
                                              key={i}
                                          >
                                              <div className="flex justify-center border-solid border border-black flex-col col-span-1">
                                                  <div className="w-full rounded">
                                                      <div className="rounded-lg flex flex-col p-8">
                                                          <div className="flex flex-col lg:flex-col">
                                                              <div className="my-1 text-white text-lg lg:text-2xl capitalize">
                                                                  <strong>
                                                                      Saleskit
                                                                  </strong>{" "}
                                                              </div>
                                                              <br></br>
                                                          </div>
                                                          <hr className="my-3"></hr>
                                                          <div className="my-1 mb-4 text-white text-sm capitalize">
                                                          <div className="lg:flex flex-wrap gap-4">
    {packages[0]?.info?.projectGallery.map((project, index) => (
        <div
            key={project._id}
            className="w-full sm:w-1/2 lg:w-1/4 mt-2 px-4 py-2 border border-gray-400 bg-orange-300 text-black font-bold hover:bg-black hover:text-white"
        >
            <a className="m-4 text-lg flex" href={project.url} target="_blank" rel="noopener noreferrer">
                {project.title}
            </a>
        </div>
    ))}
</div>

</div>


                                                          <div className="lg:flex">
                                                              {item.itinerary ? (
                                                                  <div
                                                                      onClick={() =>
                                                                          window.open(
                                                                              item.itinerary,
                                                                              "_blank"
                                                                          )
                                                                      }
                                                                      className="mt-2 mr-2 my-2 p-1 py-4 w-40 text-sm text-catskill hover:text-black hover:bg-specialgreen bg-icongreen cursor-pointer rounded-lg flex justify-center capitalize"
                                                                  >
                                                                      Itinery
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}
                                                              {item.video ? (
                                                                  <div
                                                                      onClick={() =>
                                                                          window.open(
                                                                              item.video,
                                                                              "_blank"
                                                                          )
                                                                      }
                                                                      className="mt-2 mr-2 my-2 p-1 py-4 w-40 text-sm text-catskill hover:text-black hover:bg-specialpink bg-iconpink cursor-pointer rounded-lg flex justify-center capitalize"
                                                                  >
                                                                      Video
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  );
                              })
                            : ""}
                    </div>
        </div>
    );
});

export default Saleskit;
