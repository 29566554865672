import React, { useEffect } from "react";
import { useTable, usePagination } from "react-table";
import createPagination from "../Hooks/usePagination";
import { MdCompareArrows } from "react-icons/md";

const Table = ({
    columns,
    data,
    updatePagination,
    paginationState,
    pageCount: controlledPageCount,
    sortable,
    hidePagination,
    alignLeft,
    hiddenFields,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        gotoPage,
        previousPage,
        nextPage,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            pageCount: controlledPageCount,
            initialState: {
                pageIndex: 0,
                hiddenColumns: hiddenFields ? hiddenFields : [],
            },
        },
        usePagination
    );

    useEffect(() => {
        if (updatePagination) {
            updatePagination((prev) => ({
                ...prev,
                limit: pageSize,
                page: pageIndex + 1,
            }));
        }
    }, [updatePagination, pageIndex, pageSize]);

    const { pagination } = createPagination({
        numberOfButtons: 2,
        currentPage: paginationState ? paginationState.page : pageIndex + 1,
        totalPages: paginationState
            ? paginationState.totalPages
            : controlledPageCount,
    });

    return (
        <div className="table-wrapper block lg:max-w-full overflow-y-hidden border border-orange-400">
            <table className="bg-grey p-6 text-sm text-black text-center font-semibold w-full" {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    className={`p-4 px-8 bg-black font-semibold text-white text-xs w-24`}
                                    {...column.getHeaderProps()}
                                >
                                    <div
                                        className={`flex items-center ${
                                            alignLeft
                                                ? "justify-start"
                                                : "justify-center"
                                        }`}
                                    >
                                        <div className="whitespace-no-wrap">
                                            {column.render("Header")}
                                        </div>
                                        {sortable && (
                                            <div className="cursor-pointer ml-2">
                                                {sortable.includes(
                                                    column.id
                                                ) && (
                                                    <MdCompareArrows
                                                        onClick={() => {
                                                            updatePagination(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    by: column.id,
                                                                    dir:
                                                                        prev.dir ===
                                                                        "asc"
                                                                            ? "desc"
                                                                            : "asc",
                                                                })
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr
                                className={
                                    row.original.status === "suspended"
                                        ? "bg-red-700 text-white"
                                        : row.original.status === "risksuspended"
                                        ? "bg-pink-600 text-white"
                                        : row.original.status === "cancel"
                                        ? "bg-red-700 text-white"
                                        : row.original.status === "converted"
                                        ? "bg-yellow-300 text-black"
                                        : row.original.calculatePoints ===
                                          "valid"
                                        ? "bg-yellow-300 text-black"
                                        : row.original.status === "halfconverted"
                                        ? "bg-orange-300 text-black"
                                        : ""
                                }
                                {...row.getRowProps()}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            className={`p-4 text-sm ${
                                                alignLeft
                                                    ? "text-left"
                                                    : "text-center"
                                            }`}
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {!hidePagination && (
                <div className="p-4 bg-black text-white font-semibold text-xs">
                    <ul className="flex justify-center pr-5 md:justify-end lg:justify-end">
                        <li
                            className="p-2 cursor-pointer"
                            onClick={() => previousPage()}
                        >
                            Previous
                        </li>
                        {pagination.map((page) => (
                            <li
                                className={`p-2 px-4 cursor-pointer ${
                                    page === pageIndex + 1
                                        ? "bg-darkblue text-white"
                                        : "text-xs"
                                }`}
                                key={page}
                                onClick={() => gotoPage(page - 1)}
                            >
                                {page}
                            </li>
                        ))}
                        <li
                            className="p-2 cursor-pointer"
                            onClick={() => nextPage()}
                        >
                            Next
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Table
