import React, { useState } from "react";
import queries from "../helpers/queries";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom";
import BannerOverlay from "../Components/BannerOverlay";
import {

    FcMinus,

} from "react-icons/fc";
import "../override.css";


const ProjectInfo = withRouter(() => {
    const locate = useLocation();
    const path = locate.pathname.split("/")[2];


    const [packages, setPackages] = useState(undefined);
    const [galleryModal, setGalleryModal] = useState(false);

    const [paginationState, setPaginationState] = useState({
        limit: 10,
        page: 1,
    });
    // eslint-disable-next-line no-unused-vars
    const [filters, setFilters] = useState({
        area: null,
        state: null,
    });


            useQuery(queries.FETCH_PROJECTS, {
                variables: {
                    limit: paginationState.limit,
                    page: paginationState.page,
                    area: filters.area ? filters.area : "",
                    state: filters.state ? filters.state : "",
                    project: path ? path : "",
                },
                fetchPolicy: "network-only",
                onCompleted: ({
                    fetchProjectsAgent: { pagination, ...fetchProjectsAgent },
                }) => {
                    setPaginationState(pagination);
                    setPackages(fetchProjectsAgent.data);
                },
                onError: ({ graphQLErrors, networkError }) => {
                    if (graphQLErrors) {
                        for (const graphQLError of graphQLErrors) {
                            console.log(graphQLError);
                        }
                    }
        
                    if (networkError) {
                        console.log(networkError);
                    }
                },
            });


    const handleGallerySwipe = (direction) => {
        const totalGallery = packages[0].gallery.length;
        if (direction === "right") {
            const newIndex =
                galleryModal.i + 1 < totalGallery ? galleryModal.i + 1 : 0;
            setGalleryModal({
                gallery: packages[0].gallery[newIndex],
                i: newIndex,
            });
        } else {
            const newIndex =
                galleryModal.i - 1 < 0 ? totalGallery - 1 : galleryModal.i - 1;
            setGalleryModal({
                gallery: packages[0].gallery[newIndex],
                i: newIndex,
            });
        }
    };

    return (
        <div>
            <BannerOverlay
                imageUrl={packages ? packages[0].image : ""}
                title={packages ? packages[0].name : ""}
            ></BannerOverlay>
                    <div className="grid grid-cols-1 gap-4 mb-12  tnc-background ">
                        {packages
                            ? packages.map((item, i) => {
                                  return (
                                      <div
                                          className="block border greyOpaqueBackground border-black"
                                          key={i}
                                      >
                                          <div
                                              className="grid lg:grid-cols-1"
                                              key={i}
                                          >
                                              <div className="flex justify-center border-solid border border-black flex-col col-span-1">
                                                  <div className="w-full rounded">
                                                      <div className="rounded-lg flex flex-col p-8">
                                                          <div className="flex flex-col lg:flex-col">
                                                              <div className="my-1 text-white text-lg lg:text-2xl capitalize">
                                                                  <strong>
                                                                      {
                                                                          item.name
                                                                      }
                                                                  </strong>{" "}
                                                              </div>
                                                              <div className="my-1 text-white capitalize">
                                                                  <strong>
                                                                      {
                                                                          item.info.projectLocation
                                                                      }
                                                                  </strong>{" "}
                                                              </div>
                                                              <br></br>
                                                              <div className="my-1 text-white capitalize">
                                                                  <strong>
                                                                     Developer:
                                                                  </strong>{" "}
                                                                    {item.developer}
                                                              </div>
                                                              <div className="my-1 text-white capitalize">
                                                                  <strong>
                                                                     Land Title:
                                                                  </strong>{" "}
                                                                    {item.hold}
                                                              </div>
                                                              <div className="my-1 text-white capitalize">
                                                                  <strong>
                                                                     Land Size:
                                                                  </strong>{" "}
                                                                    {item.minSqft} - {item.maxSqft} sqft
                                                              </div>
                                                              <div className="my-1 text-white capitalize">
                                                                  <strong>
                                                                     Completion Date:
                                                                  </strong>{" "}
                                                                    {item.completionDate}
                                                              </div>
                                                          </div>
                                                          <hr className="my-3"></hr>
                                                          <div className="my-1 mb-4 text-white text-sm capitalize">
                                                              <div>
                                                                  <strong>
                                                                      Person In Charge
                                                                  </strong>
                                                              </div>
                                                              <div className="lg:flex flex-wrap gap-4">
                                                                  <div className="flex-1 max-w-1/5 mt-2 px-4 py-2 border border-gray-400 bg-specialblue text-black font-bold">
                                                                      <div className="text-center text-lg ">
                                                                      Sales Director
                                                                      </div>
                                                                      <div className="text-center">
                                                                      {item.salesDirector.name}
                                                                      </div>
                                                                  </div>
                                                                  <div className="flex-1 max-w-1/5 mt-2 px-4 py-2 border border-gray-400 bg-specialpink text-black font-bold">
                                                                  <div className="text-center text-lg ">
                                                                      Project Director:
                                                                      </div>
                                                                      <div className="text-center">
                                                                      {item.projectDirector.name}
                                                                      </div>
                                                                  </div>
                                                                  <div className="flex-1 max-w-1/5 mt-2 px-4 py-2 border border-gray-400 bg-specialgreen text-black font-bold">
                                                                  <div className="text-center text-lg ">
                                                                         Coordinators
                                                                          :
                                                                      </div>
                                                                      <div className="text-center">
                                                                      {item.coordinatorName}
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>

                                                          <hr className="my-3"></hr>
                                                          <div className="my-1 text-white text-xl capitalize">
                                                              <strong>
                                                                  Total Blocks - Storey
                                                              </strong>{" "}
                                                          </div>
                                                          <div className="grid grid-cols-1 lg:grid-cols-1 row-gap-4 mb-8">
  <div className="">
    <div className="py-4">
      {item.info.projectBlocks && (
        <div className="text-white">
          {item.info.projectBlocks.split("\n").map((block, index) => (
            block ? (
              <div key={index} className="flex items-center text-white">
                <FcMinus className="mr-2" />
                <div className="font-bold">
                  {block}
                </div>
              </div>
            ) : (
              <div key={index} className="h-4"></div> // Spacer div for empty lines
            )
          ))}
        </div>
      )}
    </div>
  </div>
</div>

<hr className="my-3"></hr>
                                                          <div className="my-1 text-white text-xl capitalize">
                                                              <strong>
                                                                  Unit Details
                                                              </strong>{" "}
                                                          </div>
                                                          <div className="grid grid-cols-1 lg:grid-cols-1 row-gap-4 mb-8">
  <div className="">
    <div className="py-4">
      {item.info.projectUnits && (
        <div className="text-white">
          {item.info.projectUnits.split("\n").map((block, index) => (
            block ? (
              <div key={index} className="flex items-center text-white">
                <FcMinus className="mr-2" />
                <div className="font-bold">
                  {block}
                </div>
              </div>
            ) : (
              <div key={index} className="h-4"></div> // Spacer div for empty lines
            )
          ))}
        </div>
      )}
    </div>
  </div>
</div>

<hr className="my-3"></hr>
                                                          <div className="my-1 text-white text-xl capitalize">
                                                              <strong>
                                                                    Builds Up - Layout - Car Park - SPA Price
                                                              </strong>{" "}
                                                          </div>
                                                          <div className="grid grid-cols-1 lg:grid-cols-1 row-gap-4 mb-8">
  <div className="">
    <div className="py-4">
      {item.info.projectType && (
        <div className="text-white">
          {item.info.projectType.split("\n").map((block, index) => (
            block ? (
              <div key={index} className="flex items-center text-white">
                <FcMinus className="mr-2" />
                <div className="font-bold">
                  {block}
                </div>
              </div>
            ) : (
              <div key={index} className="h-4"></div> // Spacer div for empty lines
            )
          ))}
        </div>
      )}
    </div>
  </div>
</div>

<hr className="my-3"></hr>
                                                          <div className="my-1 text-white text-xl capitalize">
                                                              <strong>
                                                                    Sales Package
                                                              </strong>{" "}
                                                          </div>
                                                          <div className="grid grid-cols-1 lg:grid-cols-1 row-gap-4 mb-8">
  <div className="">
    <div className="py-4">
      {item.info.projectSalesPackage && (
        <div className="text-white">
          {item.info.projectSalesPackage.split("\n").map((block, index) => (
            block ? (
              <div key={index} className="flex items-center text-white">
                <FcMinus className="mr-2" />
                <div className="font-bold">
                  {block}
                </div>
              </div>
            ) : (
              <div key={index} className="h-4"></div> // Spacer div for empty lines
            )
          ))}
        </div>
      )}
    </div>
  </div>
</div>

<hr className="my-3"></hr>
                                                          <div className="my-1 text-white text-xl capitalize">
                                                              <strong>
                                                                    Project Comm Percentage
                                                              </strong>{" "}
                                                          </div>
                                                          <div className="grid grid-cols-1 lg:grid-cols-1 row-gap-4 mb-8">
  <div className="">
    <div className="py-4">
      {item.info.projectCommPercentage && (
        <div className="text-white">
          {item.info.projectCommPercentage.split("\n").map((block, index) => (
            block ? (
              <div key={index} className="flex items-center text-white">
                <FcMinus className="mr-2" />
                <div className="font-bold">
                  {block}
                </div>
              </div>
            ) : (
              <div key={index} className="h-4"></div> // Spacer div for empty lines
            )
          ))}
        </div>
      )}
    </div>
  </div>
</div>



                                                          <hr className="my-3"></hr>
                                                          <div className="my-1 text-white text-xl capitalize">
                                                              <strong>
                                                                  Remarks
                                                              </strong>{" "}
                                                          </div>

                                                          <div className="grid grid-cols-1 lg:grid-cols-1 row-gap-4 mb-8">
                                                              <div className="py-4">
                                                                  {packages
                                                                      ? packages[0].attributes.map(
                                                                            (
                                                                                item,
                                                                                i
                                                                            ) => {
                                                                                return (
                                                                                    <div
                                                                                        key={
                                                                                            i
                                                                                        }
                                                                                        className=""
                                                                                    >
                                                                                        <div className="flex items-center text-white">
                                                                                            <FcMinus className="mr-2" />
                                                                                            <div className="font-bold">
                                                                                                {
                                                                                                    item.attribute
                                                                                                }

                                                                                                :{" "}
                                                                                                {
                                                                                                    item.value
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )
                                                                      : ""}
                                                              </div>
                                                          </div>
                                                          <div className="lg:flex">
                                                              {item.itinerary ? (
                                                                  <div
                                                                      onClick={() =>
                                                                          window.open(
                                                                              item.itinerary,
                                                                              "_blank"
                                                                          )
                                                                      }
                                                                      className="mt-2 mr-2 my-2 p-1 py-4 w-40 text-sm text-catskill hover:text-black hover:bg-specialgreen bg-icongreen cursor-pointer rounded-lg flex justify-center capitalize"
                                                                  >
                                                                      Itinery
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}
                                                              {item.video ? (
                                                                  <div
                                                                      onClick={() =>
                                                                          window.open(
                                                                              item.video,
                                                                              "_blank"
                                                                          )
                                                                      }
                                                                      className="mt-2 mr-2 my-2 p-1 py-4 w-40 text-sm text-catskill hover:text-black hover:bg-specialpink bg-iconpink cursor-pointer rounded-lg flex justify-center capitalize"
                                                                  >
                                                                      Video
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  );
                              })
                            : ""}
                    </div>

            {galleryModal && (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center">
                    <div
                        className="absolute top-0 left-0 w-full h-full bg-black opacity-100"
                        onClick={() => setGalleryModal(false)}
                    ></div>
                    <div className="relative flex justify-center items-center w-full h-full">
                        <div
                            className="absolute top-0 right-0 m-4 text-xl cursor-pointer z-50 bg-yellow-400 p-2 font-bold text-black"
                            onClick={() => setGalleryModal(false)}
                        >
                            Close Gallery
                        </div>
                        <div className="relative w-full max-w-lg">
                            <img
                                src={
                                    packages
                                        ? packages[0].gallery[galleryModal.i]
                                              .img
                                        : ""
                                }
                                alt={`Gallery ${galleryModal.i + 1}`}
                                className="object-cover w-full h-full z-50"
                                onClick={() => setGalleryModal(galleryModal)}
                            />
                        </div>
                        <div className="absolute top-0 bottom-0 flex justify-between items-center w-full px-4">
                            <div
                                className="w-12 h-12 flex items-center justify-center bg-white rounded-full cursor-pointer opacity-50 z-50"
                                onClick={() => handleGallerySwipe("left")}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M15 19l-7-7 7-7"
                                    />
                                </svg>
                            </div>
                            <div
                                className="w-12 h-12 flex items-center justify-center bg-white rounded-full cursor-pointer opacity-50 z-50"
                                onClick={() => handleGallerySwipe("right")}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
});

export default ProjectInfo;
