import React, { useState, useMemo } from "react";
import Select from "react-select";
import queries from "../helpers/queries";
import { withUser } from "../Contexts/user";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "../Components/slider";
import { FiX } from "react-icons/fi";
import { useQuery } from "@apollo/react-hooks";
import AwardsType from "../AwardsType";
import { RiFolderSettingsLine } from "react-icons/ri";
import { FiMap } from "react-icons/fi";

const Banners = () => {
    const [menuMobile, setMenuMobile] = useState(false);
    const [stateMobile, setStateMobile] = useState(false);
    const [states, setStates] = useState([]);
    const [filters, setFilters] = useState({
        state: { value: "626cc8bf39ad53148a3faad3", label: "Kuala Lumpur" },
        type: "agentpersonalsales",
    });

    const { data: bannersData } = useQuery(queries.FETCH_BANNERS_AGENT, {
        variables: {
            state: filters.state ? filters.state.value : "",
            type: filters.type,
            isMobile: window.innerWidth < 600 ? true : false,
        },
        fetchPolicy: "network-only",
    });

    const filterSelectStyles = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                backgroundColor: "#000000",
                borderRadius: "0.5rem",
                color: "#FFFFFF",
                padding: "0.20rem",
            }),
            singleValue: (provided, state) => ({
                ...provided,
                color: "#FFFFFF",
            }),
            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#000000",
                fontSize: "0.75rem",
                fontWeight: "600",
                width: "100%",
            }),
        }),
        []
    );

    useQuery(queries.FETCH_ALL_STATES, {
        fetchPolicy: "network-only",
        onCompleted: ({ fetchStates: _id, name }) => {
            setStates(_id, name);
        },
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                for (const graphQLError of graphQLErrors) {
                    console.log(graphQLError);
                }
            }

            if (networkError) {
                console.log(networkError);
            }
        },
    });

    return (
        <div>
            <div className="lg:flex md:flex block">
                {menuMobile && (
                    <div className="lg:hidden pt-8 fixed inset-0 w-full h-full bg-black bg-opacity-90 z-10 overflow-scroll">
                        <div
                            className={`flex ${"text-cool-gray"} p-3 justify-end m-2 items-center text-lg`}
                        >
                            <div
                                onClick={() => setMenuMobile(false)}
                                className="text-2xl md:text-3xl"
                            >
                                <FiX></FiX>
                            </div>
                        </div>
                        {AwardsType.map(({ ...route }, i) => {
                            // eslint-disable-next-line
                            if (route.hidden) return;

                            return (
                                <div
                                    className={`flex hover:text-iconblue ${"text-cool-gray"} hover:bg-specialblue p-3 justify-end m-2 items-center text-lg`}
                                    key={i}
                                    onClick={() => setMenuMobile(false)}
                                    to={route.path}
                                >
                                    <div
                                        onClick={() =>
                                            setFilters((prev) => ({
                                                ...prev,
                                                type: route.value,
                                            }))
                                        }
                                        className="text-base md:text-3xl"
                                    >
                                        - {route.name}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}

                {stateMobile && (
                    <div className="lg:hidden pt-8 fixed inset-0 w-full h-full bg-black bg-opacity-90 z-10 overflow-scroll">
                        <div
                            className={`flex ${"text-cool-gray"} p-3 justify-end m-2 items-center text-lg`}
                        >
                            <div
                                onClick={() => setStateMobile(false)}
                                className="text-2xl md:text-3xl"
                            >
                                <FiX></FiX>
                            </div>
                        </div>
                        {states.map(({ ...state }, i) => {
                            return (
                                <div
                                    className={`flex hover:text-iconblue ${"text-cool-gray"} hover:bg-specialblue p-3 justify-start m-2 items-center text-lg`}
                                    key={i}
                                    onClick={() => {
                                        setFilters((prev) => ({
                                            ...prev,
                                            state: {
                                                value: state._id,
                                                label: state.name,
                                            },
                                        }));
                                        setStateMobile(false);
                                    }}
                                >
                                    <div className="text-base md:text-3xl">
                                        - {state.name}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}

                <div className="hidden lg:block sidebar bg-black min-h-screen text-white min-w-48 w-2/12 flex-shrink-0">
                    <div className="flex relative justify-center h-32 my-2">
                        <img
                            className=""
                            src={
                                "https://ik.imagekit.io/dnddecpho/dndream/award(1)_ERrw5Adt8.png?updatedAt=1703674482997"
                            }
                            alt={""}
                        ></img>
                    </div>
                    <div className="py-4 px-2 mb-5">
                        <Select
                            styles={filterSelectStyles}
                            isSearchable={false}
                            options={states.map((state) => ({
                                label: state.name,
                                value: state._id,
                            }))}
                            value={filters.state}
                            placeholder="State"
                            onChange={(val) =>
                                setFilters((prev) => ({
                                    ...prev,
                                    state: val,
                                }))
                            }
                        />
                    </div>
                    {AwardsType.map(({ Icon, ...route }, i) => {
                        return (
                            <div
                                className={`flex cursor-pointer text-sm font-semibold ${
                                    filters.type === route.value
                                        ? "text-orange-400 font-black underline"
                                        : "text-cool-gray"
                                } hover:underline underline-offset p-2 items-center`}
                                key={i}
                                onClick={() =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        type: route.value,
                                    }))
                                }
                            >
                                <div>{route.name}</div>
                            </div>
                        );
                    })}
                </div>
                <div className="block sidebar bg-black min-h-screen text-white min-w-48 md:w-full lg:w-10/12 flex-shrink-0">
                    <div className="lg:hidden flex justify-start p-2">
                        <div
                            className="mr-auto"
                            onClick={() => {
                                setStateMobile((prev) => !prev);
                            }}
                        >
                            <FiMap className="text-4xl"></FiMap>
                        </div>
                        <div
                            className="ml-auto"
                            onClick={() => {
                                setMenuMobile((prev) => !prev);
                            }}
                        >
                            <RiFolderSettingsLine className="text-4xl"></RiFolderSettingsLine>
                        </div>
                    </div>
                    <Slider
                        data={
                            bannersData
                                ? bannersData.fetchBannersAgent.map((item) => ({
                                      ...item,
                                      backgroundclass:
                                          item[0].type === "mvp"
                                              ? "mvp-background"
                                              : item[0].type === "omc"
                                              ? "omc-background"
                                              : item[0].type === "pmc"
                                              ? "pmc-background"
                                              : item[0].type === "emc"
                                              ? "emc-background"
                                              : item[0].type === "smc"
                                              ? "smc-background"
                                              : item[0].type === "trip"
                                              ? "trip-background"
                                              : item[0].type === "carsubsidy"
                                              ? "car-background"
                                              : item[0].type === "bestrookie"
                                              ? "bestrookie-background"
                                              : item[0].type === "toprookie"
                                              ? "toprookie-background"
                                              : item[0].type === "agentpersonalsales"
                                              ? "agentpersonal-background"
                                              : item[0].type === "leaderpersonalsales"
                                              ? "leaderpersonal-background"
                                              : item[0].type === "primepersonalsales"
                                              ? "primepersonal-background"
                                              : item[0].type === "leadergroupsales"
                                              ? "leadergroup-background"
                                              : item[0].type === "primegroupsales"
                                              ? "personalgroup-background"
                                              : "slider-background",
                                  }))
                                : []
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default withUser(Banners);
