import React, { useState, useMemo } from "react";
import queries from "../helpers/queries";
import { withUser } from "../Contexts/user";
import ReactPlayer from "react-player";
import { FaRegPlayCircle } from "react-icons/fa";
import Table from "../Components/table";
import { useQuery } from "@apollo/react-hooks";

const Videos = () => {
  const [activeLink, setActiveLink] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "Video Name",
        accessor: "name",
      },
      {
        Header: "",
        id: "actions",
        Cell: ({ row: { original } }) => {
          return (
            <div className="flex justify-end mr-8 items-start">
              <div
                className="cursor-pointer"
                onClick={() => setActiveLink(original.url)}
              >
                <FaRegPlayCircle className="text-xl" />
              </div>
            </div>
          );
        },
      },
    ],
    []
  );
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    page: 1,
  });

  const {
    loading: videosLoading,
    data: videosData,
  } = useQuery(queries.FETCH_VIDEOS, {
    variables: {
      limit: 5,
      page: paginationState.page,
    },
    fetchPolicy: "network-only",
    onCompleted: ({ fetchVideos: { pagination } }) =>
      setPaginationState(pagination),
  });
  return (
    <div className="min-h-screen bg-cover"
    style={{
        backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url('https://ik.imagekit.io/dnddecpho/dndream/D&D_SystemImages_4pSPeG_vc.png?updatedAt=1718198005087')",
    }} >
      <ReactPlayer
        url={
          videosData
            ? activeLink
              ? activeLink
              : videosData.fetchVideos.data[0].url
            : ""
        }
        width="auto"
        height="50vh"
        controls={true}
        playing={activeLink ? true : false}
      />
      <div className="m-8">
        <Table
          columns={columns}
          data={videosData ? videosData.fetchVideos.data : []}
          updatePagination={setPaginationState}
          loading={videosLoading}
          pageCount={
            videosData ? videosData.fetchVideos.pagination.totalPages : 0
          }
          totalCount={
            videosData ? videosData.fetchVideos.pagination.documents : 0
          }
          alignLeft
        />
      </div>
    </div>
  );
};

export default withUser(Videos);
