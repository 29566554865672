import React from "react";
import { Carousel } from "react-responsive-carousel";
import "../override.css";

const Campaign = ({ data }) => {
    return (
        <div className="">
            <Carousel>
                {data.map((item, i) => (
                    <div
                        className="lg:flex block tnc-background"
                        key={i}
                    >
                        <div className="lg:w-6/12 m-auto">
                            <img
                                key={i}
                                className="banner-image"
                                alt=""
                                src={item.url}
                            />
                        </div>
                        <div className="lg:w-6/12 greyOpaqueBackground m-auto">
                            <div className="text-3xl lg:text-3xl md:text-5xl m-8 font-bold mx-16 font-roboto">
                                Terms & Conditions
                            </div>
                            <div
                                className={`font-roboto lg:text-base md:text-3xl lg:leading-6 leading-6 md:leading-8 tracking-wide mx-16 mb-10`}
                            >
                                {item.enNotesArray.map((term) => {
                                    if (term === ".") {
                                        return (
                                            <div
                                            className="mb-2 md:text-center lg:text-left text-left"
                                                key={term}
                                            >
                                                <br />
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div
                                                className="mb-2 md:text-center lg:text-left text-left"
                                                key={term}
                                            >
                                                {term}
                                            </div>
                                        );
                                    }
                                })}
                                {item.cnNotesArray.map((term) => {
                                    if (term === ".") {
                                        return (
                                            <div
                                            className="mb-2 md:text-center lg:text-left text-left"
                                                key={term}
                                            >
                                                <br />
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div
                                                className="mb-2 md:text-center lg:text-left text-left"
                                                key={term}
                                            >
                                                {term}
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default Campaign;
