const AwardTypes = [
    {
        value: "omc",
        name: "OMC",
    },
    {
        value: "emc",
        name: "EMC",
    },
  
    {
        value: "pmc",
        name: "PMC",
    },
    {
        value: "smc",
        name: "SMC",
    },
    {
        value: "agentpersonalsales",
        name: "Agent Personal",
    },
    {
        value: "leaderpersonalsales",
        name: "Leader Personal",
    },
    {
        value: "primepersonalsales",
        name: "Prime Personal",
    },
    {
        value: "leadergroupsales",
        name: "Leader Group",
    },

    {
        value: "primegroupsales",
        name: "Prime Group",
    },
    {
        value: "bestrookie",
        name: "Best Rookie",
    },
    {
        value: "toprookie",
        name: "Top Rookie",
    },
    {
        value: "mvp",
        name: "MVP",
    },
    {
        value: "queendompersonal",
        name: "Queendom Personal",
    },
    {
        value: "trip",
        name: "Trip",
    },
    {
        value: "carsubsidy",
        name: "Car Subsidy",
    },

];

export default AwardTypes