import React, { useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import queries from "../helpers/queries";
import { useForm } from "react-hook-form";
import { withUser } from "../Contexts/user";
import { FaFileDownload } from "react-icons/fa";
import { IoIosAdd } from "react-icons/io";
import Table from "../Components/darkBasicTable";
import createPdf from "../Components/createPdf";
import multiPdf from "../Components/multiPdf";
import moment from "moment";
import { useQuery } from "@apollo/react-hooks";
import useDimensions from "../Hooks/useDimensions";
import Select from "react-select";

const statusOptions = [
    {
        label: "All",
        value: "all",
    },
    {
        label: "Valid",
        value: "valid",
    },
    {
        label: "Void",
        value: "void",
    },
];

const PaymentVouchers = withRouter(() => {
    const size = useDimensions();

    const filterSelectStyles = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderColor: "#e2e8f0",
                backgroundColor: "#fbfdfe",
                borderRadius: "0.5rem",
                padding: "0.20rem",
                "&:hover": {
                    borderColor: "#e2e8f0",
                },
            }),

            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#64748b",
                fontSize: "0.75rem",
                fontWeight: "600",
                width: "100%",
            }),
        }),
        []
    );

    useForm({
        defaultValues: {
            payee: "",
            method: undefined,
            date: "",
            batch: "",
            name: "",
            nickname: "",
            amount: "",
            status: "valid",
        },
    });

    useForm({
        defaultValues: {
            status: {
                label: "Valid",
                value: "valid",
            },
        },
    });

    const [filters, setFilters] = useState({
        dateTo: moment(Date.now()).format("YYYY-MM-DD"),
        dateFrom: moment(Date.now()).subtract(1, "months").format("YYYY-MM-DD"),
        calculatePoints: "all",
    });

    const columns = useMemo(() => {
        const NumberFormatter = new Intl.NumberFormat("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        if (size.width > 650) {
            return [
                {
                    Header: "Agent",
                    accessor: "payee",
                    Cell: ({ value }) => (
                        <div className="capitalize">{value.name}</div>
                    ),
                },
                {
                    Header: "Method",
                    accessor: "method",
                    Cell: ({ value }) => (
                        <div className="capitalize">{value}</div>
                    ),
                },
                {
                    Header: "Date",
                    accessor: "date",
                },
                {
                    Header: "Order No",
                    accessor: "orderNo",
                    Cell: ({ value }) => (
                        <div className="capitalize">{value}</div>
                    ),
                },
                {
                    Header: "Nickname",
                    accessor: "nickname",
                    Cell: ({ row: { original } }) => (
                        <div className="capitalize">
                            {original.payee.nickname}
                        </div>
                    ),
                },
                {
                    Header: "Unit",
                    accessor: "unit",
                    Cell: ({ row: { original } }) => (
                        <div>{original.item.address}</div>
                    ),
                },
                {
                    Header: "Amount",
                    id: "amount",
                    Cell: ({ row: { original } }) => (
                        <div>
                            RM{" "}
                            {NumberFormatter.format(
                                original.item.amount +
                                    original.item2.amount +
                                    original.item3.amount +
                                    original.item4.amount +
                                    original.item5.amount
                            )}
                        </div>
                    ),
                },
                {
                    Header: "",
                    id: "actions",
                    Cell: ({ row: { original } }) => {
                        return (
                            <div className="flex content-start items-center">
                                <div className="cursor-pointer flex justify-center">
                                    <FaFileDownload
                                        onClick={() => createPdf(original)}
                                    />
                                </div>
                            </div>
                        );
                    },
                },
            ];
        } else {
            return [
                {
                    Header: "Nickname",
                    accessor: "nickname",
                    Cell: ({ row: { original } }) => (
                        <div className="capitalize text-xs">
                            {original.payee.nickname}
                        </div>
                    ),
                },
                {
                    Header: "Amount(RM)",
                    accessor: "amount",
                    Cell: ({ row: { original } }) => {
                        let amount2, amount3, amount4, amount5;
                        // eslint-disable-next-line
                        if (original.item2.amount == false) {
                            amount2 = 0;
                        } else {
                            amount2 = parseFloat(original.item2.amount);
                        }
                        // eslint-disable-next-line
                        if (original.item3.amount == false) {
                            amount3 = 0;
                        } else {
                            amount3 = parseFloat(original.item3.amount);
                        }
                        // eslint-disable-next-line
                        if (original.item4.amount == false) {
                            amount4 = 0;
                        } else {
                            amount4 = parseFloat(original.item4.amount);
                        }
                        // eslint-disable-next-line
                        if (original.item5.amount == false) {
                            amount5 = 0;
                        } else {
                            amount5 = parseFloat(original.item5.amount);
                        }

                        const total =
                            parseFloat(original.item.amount) +
                            amount2 +
                            amount3 +
                            amount4 +
                            amount5;

                        return (
                            <div className="text-xs">{total.toFixed(2)}</div>
                        );
                    },
                },
            ];
        }
    }, [size]);

    const [paginationState, setPaginationState] = useState({
        limit: 9999,
        page: 1,
    });

    const { loading: paymentVouchersLoading, data: paymentVouchersData } =
        useQuery(queries.FETCH_USER_PAYMENT_VOUCHERS, {
            variables: {
                limit: paginationState.limit,
                page: paginationState.page,
                dateFrom: filters.dateFrom,
                dateTo: filters.dateTo,
                calculatePoints: filters.calculatePoints.value,
            },
            fetchPolicy: "network-only",
            onCompleted: ({ fetchUserPaymentVouchers: { pagination } }) =>
                setPaginationState(pagination),
        });

    const NumberFormatter = new Intl.NumberFormat("en-GB", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return (
        <div
            className="min-h-screen bg-cover"
            style={{
                backgroundImage:
                    "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url('https://ik.imagekit.io/dnddecpho/dndream/D&D_SystemImages_4pSPeG_vc.png?updatedAt=1718198005087')",
            }}
        >
            <div className="py-4 px-6 lg:px-12">
                <div className="table-header-actions flex flex-wrap justify-between items-start mb-5">
                    <div className="w-full lg:pr-4">
                        <div className="grid grid-cols-2 lg:grid-cols-3 mb-4 col-gap-4 row-gap-4">
                            <div className="">
                                <input
                                    className="w-full p-1 lg:p-3 h-full px-4 rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs"
                                    type="date"
                                    placeholder="YYYY-MM-DD"
                                    onChange={(e) => {
                                        e.persist();

                                        setFilters((prev) => ({
                                            ...prev,
                                            dateFrom: e.target.value,
                                        }));

                                        setPaginationState((prev) => ({
                                            ...prev,
                                            page: 1,
                                        }));
                                    }}
                                    value={filters.dateFrom}
                                />
                            </div>
                            <div className="">
                                <input
                                    className="w-full p-1 lg:p-3 h-full px-4 rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs"
                                    type="date"
                                    placeholder="YYYY-MM-DD"
                                    onChange={(e) => {
                                        e.persist();

                                        setFilters((prev) => ({
                                            ...prev,
                                            dateTo: e.target.value,
                                        }));
                                        setPaginationState((prev) => ({
                                            ...prev,
                                            page: 1,
                                        }));
                                    }}
                                    value={filters.dateTo}
                                />
                            </div>
                            <div className="">
                                <Select
                                    styles={filterSelectStyles}
                                    isSearchable={false}
                                    options={statusOptions}
                                    value={filters.calculatePoints}
                                    placeholder="Calculate Points"
                                    onChange={(val) => {
                                        setPaginationState((prev) => ({
                                            ...prev,
                                            page: 1,
                                        }));
                                        setFilters((prev) => ({
                                            ...prev,
                                            calculatePoints: val,
                                        }));
                                    }}
                                />
                            </div>
                            <div
                                className={`${
                                    paymentVouchersData !== undefined
                                        ? "font-semibold bg-specialblue hover:bg-iconblue hover:text-white py-2 px-3 lg:mr-1 rounded w-full w-1/2 lg:mr-2 lg:w-1/3 cursor-pointer"
                                        : "hidden"
                                }`}
                                onClick={() => {
                                    multiPdf(
                                        paymentVouchersData
                                            .fetchUserPaymentVouchers.data
                                    )
                                }}
                            >
                                <IoIosAdd className="inline" /> Download All
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-8 pb-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-4 col-gap-4">
                    <div className="">
                        <div className="flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground border borderorange font-semibold items-center">
                        <img className="h-full w-16" src="https://ik.imagekit.io/dnddecpho/SSR/D&D_SystemImages_Icon-05_TSMY7dRdQ.png?updatedAt=1719631002438" alt="totalvoucher" />
                            <div className="ml-4 text-white text-sm">
                                Total Voucher:{" "}
                                <div className="text-sm lg:text-2xl">
                                    {paymentVouchersData
                                        ? paymentVouchersData
                                              .fetchUserPaymentVouchers.totals
                                            ? paymentVouchersData
                                                  .fetchUserPaymentVouchers
                                                  .totals.totalPaymentVoucher
                                            : "0"
                                        : "0"}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="">
                        <div className="flex p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground borderorange border font-semibold items-center">
                        <img className="h-full w-16" src="https://ik.imagekit.io/dnddecpho/SSR/D&D_SystemImages_Icon-02_5pwSHlrtR.png?updatedAt=1719631002514" alt="totalicon" />
                            <div className="ml-4 text-white text-sm">
                                Total Amount:{" "}
                                <div className="text-sm lg:text-2xl">
                                    RM
                                    {paymentVouchersData
                                        ? paymentVouchersData
                                              .fetchUserPaymentVouchers.totals
                                            ? NumberFormatter.format(
                                                  paymentVouchersData
                                                      .fetchUserPaymentVouchers
                                                      .totals.totalVoucherAmount
                                              )
                                            : "0"
                                        : "0"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Table
                    columns={columns}
                    data={
                        paymentVouchersData
                            ? paymentVouchersData.fetchUserPaymentVouchers.data
                            : []
                    }
                    updatePagination={setPaginationState}
                    loading={paymentVouchersLoading}
                    pageCount={
                        paymentVouchersData
                            ? paymentVouchersData.fetchUserPaymentVouchers
                                  .pagination.totalPages
                            : 0
                    }
                    totalCount={
                        paymentVouchersData
                            ? paymentVouchersData.fetchUserPaymentVouchers
                                  .pagination.documents
                            : 0
                    }
                />
            </div>
        </div>
    );
});

export default withUser(PaymentVouchers);
