import React, { useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import queries from "../helpers/queries";
import { useForm } from "react-hook-form";
import { withUser } from "../Contexts/user";
import Table from "../Components/darkBasicTable";
import { useQuery } from "@apollo/react-hooks";
import useDimensions from "../Hooks/useDimensions";
import Select from "react-select";
import _ from "lodash";

const statusOptions = [
    {
        label: "All",
        value: "all",
    },
    {
        label: "Active",
        value: "active",
    },
    {
        label: "Risk Suspended",
        value: "risksuspended",
    },
    {
        label: "Suspended",
        value: "suspended",
    },
];

const rankings = [
    {
        label: "All",
        value: "all",
    },
    {
        label: "Prime Leader",
        value: "5ec264312bde7cefbf119c4c",
    },
    {
        label: "Leader",
        value: "5ec2676c877421f075d0cbff",
    },
    {
        label: "Agent",
        value: "5ec5f937dfa89a2ccea9e9d9",
    },
];

const NoConvertDownlines = withRouter(() => {
    const size = useDimensions();

    const {
        handleSubmit: handleFilterSubmit,
        reset,
        errors,
    } = useForm({
        defaultValues: {
            name: "",
            referral: null,
        },
    });

    const filterSelectStyles = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                backgroundColor: "#fbfdfe",
                borderRadius: "0.5rem",
                padding: "0.20rem",
                "&:hover": {
                    borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                },
            }),

            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#64748b",
                fontSize: "0.75rem",
                fontWeight: "600",
                width: "100%",
            }),
        }),
        [errors]
    );

    const [filters, setFilters] = useState({
        name: undefined,
        referral: undefined,
        status: "active",
        rankings: "all",
    });

    const updateFilters = (values) => {
        setFilters((prev) => ({
            ...prev,
            ...values,
            name: values.name !== "" ? values.name.value : filters.name,
            referral:
                values.referral !== null
                    ? values.referral.value
                    : filters.referral,
            status:
                values.status !== undefined
                    ? values.status.value
                    : filters.status,
            rankings:
                values.rankings !== undefined
                    ? values.rankings.value
                    : filters.rankings,
        }));

        setPaginationState((prev) => ({ ...prev, page: 1 }));
    };

    const columns = useMemo(() => {
        const NumberFormatter = new Intl.NumberFormat("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        if (size.width > 650) {
            return [
                {
                    Header: "Name",
                    accessor: "name",
                    Cell: ({ value }) => (
                        <div className="capitalize">{value}</div>
                    ),
                },
                {
                    Header: "Ranking",
                    accessor: "progression",
                    Cell: ({ value }) => (
                        <div className="capitalize">{value}</div>
                    ),
                },
                {
                    Header: "No Case",
                    accessor: "risk",
                    Cell: ({ value }) => (
                        <div className="capitalize">
                            {value === "lv1"
                                ? "1 Week"
                                : value === "lv0"
                                ? "New"
                                : value === "lv2"
                                ? "2 Weeks"
                                : value === "lv3"
                                ? "3 Weeks"
                                : value === "lv4"
                                ? "1 Month"
                                : value === "lv5"
                                ? "2 Months"
                                : value === "lv6"
                                ? "3 Months"
                                : "clear"}
                        </div>
                    ),
                },
                {
                    Header: "No Convert",
                    accessor: "noConvert",
                    Cell: ({ value }) => (
                        <div className="capitalize">
                            {value === "lv1"
                                ? "1 Week"
                                : value === "lv0"
                                ? "New"
                                : value === "lv2"
                                ? "2 Weeks"
                                : value === "lv3"
                                ? "3 Weeks"
                                : value === "lv4"
                                ? "1 Month"
                                : value === "lv5"
                                ? "2 Months"
                                : value === "lv6"
                                ? "3 Months"
                                : "clear"}
                        </div>
                    ),
                },
                {
                    Header: "Upline",
                    accessor: "referral",
                    Cell: ({ value }) => (
                        <div className="capitalize">{value.name}</div>
                    ),
                },
                {
                    Header: "Second Upline",
                    accessor: "secondReferral",
                    Cell: ({ value }) => (
                        <div className="capitalize">
                            {value ? value.name : ""}
                        </div>
                    ),
                },
            ];
        } else {
            return [
                {
                    Header: "Name",
                    accessor: "name",
                    Cell: ({ value }) => (
                        <div className="capitalize">{value}</div>
                    ),
                },
                {
                    Header: "Personal Sales",
                    accessor: "personalSales",
                    Cell: ({ value }) => (
                        <div>{NumberFormatter.format(value)}</div>
                    ),
                },
                {
                    Header: "Group Sales",
                    accessor: "groupSales",
                    Cell: ({ value }) => (
                        <div>{NumberFormatter.format(value)}</div>
                    ),
                },
            ];
        }
    }, [size]);

    const [paginationState, setPaginationState] = useState({
        limit: 10,
        page: 1,
    });

    const { loading: downlinesLoading, data: downlinesData } = useQuery(
        queries.FETCH_NO_CONVERT_DOWNLINES,
        {
            variables: {
                limit: paginationState.limit,
                page: paginationState.page,
                name: filters.name,
                upline: filters.referral,
                status: filters.status,
                rank: filters.rankings,
            },
            fetchPolicy: "network-only",
            onCompleted: ({ fetchNoConvertDownlines: { pagination } }) =>
                setPaginationState(pagination),
        }
    );

    return (
        <div
            className="min-h-screen bg-cover"
            style={{
                backgroundImage:
                    "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url('https://ik.imagekit.io/dnddecpho/dndream/D&D_SystemImages_4pSPeG_vc.png?updatedAt=1718198005087')",
            }}
        >
            <div className="py-4 px-12 h-full">
                <div className="w-full lg:pr-4">
                    <form
                        className="grid grid-cols-1 lg:grid-cols-3 mb-4 col-gap-4 row-gap-4"
                        onSubmit={handleFilterSubmit(updateFilters)}
                    >
                        <div className="">
                            <Select
                                styles={filterSelectStyles}
                                isSearchable={false}
                                options={statusOptions}
                                value={filters.status}
                                placeholder={_.capitalize(filters.status)}
                                onChange={(val) => {
                                    setPaginationState((prev) => ({
                                        ...prev,
                                        page: 1,
                                    }));
                                    setFilters((prev) => ({
                                        ...prev,
                                        status: val.value,
                                    }));
                                }}
                            />
                        </div>
                        <div className="">
                            <Select
                                styles={filterSelectStyles}
                                isSearchable={false}
                                options={rankings}
                                value={filters.rankings}
                                placeholder={
                                    filters.rankings ===
                                    "5ec5f937dfa89a2ccea9e9d9"
                                        ? "Agent"
                                        : filters.rankings ===
                                          "5ec2676c877421f075d0cbff"
                                        ? "Leader"
                                        : filters.rankings === "all"
                                        ? "All"
                                        : "Prime Leader"
                                }
                                onChange={(val) => {
                                    setPaginationState((prev) => ({
                                        ...prev,
                                        page: 1,
                                    }));
                                    setFilters((prev) => ({
                                        ...prev,
                                        rankings: val.value,
                                    }));
                                }}
                            />
                        </div>
                        <div></div>
                        <div className="flex flex-row justify-start items-center lg:justify-start text-center">
                            <div
                                className="inline bg-icongreen text-white w-1/2 lg:w-1/3 px-4 text-medium border-solid cursor-pointer border border-gray-400 text-sm p-2"
                                onClick={() => {
                                    setFilters((prev) => ({
                                        ...prev,
                                        name: null,
                                        upline: null,
                                    }));
                                    reset();
                                }}
                            >
                                Reset
                            </div>
                        </div>
                    </form>
                </div>
                <div className="mt-6 table-header-actions flex justify-end items-start"></div>
                <Table
                    columns={columns}
                    data={
                        downlinesData
                            ? downlinesData.fetchNoConvertDownlines.data
                            : []
                    }
                    updatePagination={setPaginationState}
                    loading={downlinesLoading}
                    pageCount={
                        downlinesData
                            ? downlinesData.fetchNoConvertDownlines.pagination
                                  .totalPages
                            : 0
                    }
                    totalCount={
                        downlinesData
                            ? downlinesData.fetchNoConvertDownlines.pagination
                                  .documents
                            : 0
                    }
                />
            </div>
        </div>
    );
});

export default withUser(NoConvertDownlines);
