const CampaignType = [
    {
        value: "trip",
        name: "Incentive Trip",
    },
    {
        value: "campaign",
        name: "Special Campaign",
    },
    {
        value: "car",
        name: "Car Incentive",
    },
];

export default CampaignType
