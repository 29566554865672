import React, { useState } from "react";
import queries from "../helpers/queries";
import { withUser } from "../Contexts/user";
import { FiX } from "react-icons/fi";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Campaign from "../Components/campaign";
import { useQuery } from "@apollo/react-hooks";
import CampaignsType from "../CampaignsType";
import { RiFolderSettingsLine } from "react-icons/ri";

const Campaigns = () => {
    const [campaignFilter, setCampaignFilter] = useState("trip");
    const [menuMobile, setMenuMobile] = useState(false);
    const { data: campaignsData } = useQuery(queries.FETCH_CAMPAIGNS_AGENT, {
        fetchPolicy: "network-only",
    });

    return (
        <div className="flex">
            {menuMobile && (
                <div className="lg:hidden pt-8 fixed inset-0 w-full h-full bg-black bg-opacity-90 z-10 overflow-scroll">
                    <div
                        className={`flex ${"text-cool-gray"} p-3 justify-end m-2 items-center text-lg`}
                    >
                        <div
                            onClick={() => setMenuMobile(false)}
                            className="text-2xl md:text-3xl"
                        >
                            <FiX></FiX>
                        </div>
                    </div>
                    {CampaignsType.map(({ ...route }, i) => {
                        // eslint-disable-next-line
                        if (route.hidden) return;

                        return (
                            <div
                                className={`flex hover:text-iconblue ${"text-cool-gray"} hover:bg-specialblue p-3 justify-end m-2 items-center text-lg`}
                                key={i}
                                onClick={() => setMenuMobile(false)}
                            >
                                <div
                                    onClick={() =>
                                        setCampaignFilter(route.value)
                                    }
                                    className="text-base md:text-3xl"
                                >
                                    - {route.name}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}

                <div className="hidden lg:block sidebar bg-black min-h-screen text-white min-w-48 w-2/12 flex-shrink-0">
                    <div className="flex relative justify-center h-32 my-4">
                        <img
                            className=""
 src="https://ik.imagekit.io/dnddecpho/dndream/campaign_UxMDBF0ld.png?updatedAt=1706517168592"
                            alt={""}
                        ></img>
                    </div>

                {CampaignsType.map(({ Icon, ...route }, i) => {
                        return (
                            <div
                                className={`flex cursor-pointer text-sm font-semibold ${
                                    campaignFilter === route.value
                                        ? "text-orange-400 font-black underline"
                                        : "text-cool-gray"
                                } hover:underline underline-offset p-2 items-center`}
                                key={i}
                                onClick={() =>
                                    {
                                        setCampaignFilter(route.value)
                                    }
                                }
                            >
                                <div>{route.name}</div>
                            </div>
                        );
                    })}
            </div>
            <div className="block sidebar bg-black text-white min-w-48 lg:w-10/12 w-full flex-shrink-0">
                <div
                    className="lg:hidden flex justify-end"
                    onClick={() => {
                        setMenuMobile((prev) => !prev);
                    }}
                >
                    <RiFolderSettingsLine className="text-4xl"></RiFolderSettingsLine>
                </div>
                <Campaign
                    data={
                        campaignsData
                            ? campaignsData.fetchCampaignsAgent.filter(
                                  (item) => item.type === campaignFilter
                              )
                            : []
                    }
                />
            </div>
        </div>
    );
};

export default withUser(Campaigns);
