import Dashboard from "./Pages/Dashboard";
import Projects from "./Pages/Projects";
import Cases from "./Pages/Cases";
import Chat from "./Pages/Chat";
import PaymentVouchers from "./Pages/PaymentVouchers";
import Videos from "./Pages/Videos";
import Banners from "./Pages/Banners";
import Teams from "./Pages/Teams";
import { FcPositiveDynamic, FcBusinessman,FcSalesPerformance, FcGraduationCap, FcDiploma2, FcCamcorderPro, FcIdea, FcBriefcase, FcHighPriority, FcStumbleupon , FcBarChart, FcDislike, FcPlanner ,  FcLineChart, FcAbout, FcAdvertising , FcAcceptDatabase  } from "react-icons/fc";
import {
  FiImage,
} from "react-icons/fi";
import ChangePassword from "./Pages/ChangePassword";
import EditProfile from "./Pages/EditProfile";
import Campaigns from "./Pages/Campaigns";
import SalesRankingPercentage from "./Pages/SalesRankingPercentage";
import CancelledPercentage from "./Pages/CancelledPercentage";
import SignedPercentage from "./Pages/SignedPercentage";
import DownlineReport from "./Pages/DownlineReport";
import RiskyDownlines from "./Pages/RiskyDownlines";
import MonthlyPerformance from "./Pages/MonthlyPerformance";
import WeeklyPerformance from "./Pages/WeeklyPerformance";
import RegionSalesRankingPercentage from "./Pages/RegionSalesRankingPercentage";
import RegionCancelledPercentage from "./Pages/RegionCancelledPercentage";
import NoConvertDownlines from "./Pages/NoConvertDownlines";
import WhatsappDemo from "./Pages/WhatsappDemo";
import Terms from "./Pages/Terms";
import ManualDownlineReport from "./Pages/ManualDownlineReport";
import PDReport from "./Pages/MonthlyPDReport";
import SDReport from "./Pages/MonthlySDReport";
import CoordinatorReport from "./Pages/MonthlyCoordinatorReport";

const Routes =  [
  {
    path: "/",
    name: "Profile",
    Icon: FcBusinessman,
    component: Dashboard,
  },
  {
    path: "/projects",
    name: "Projects",
    Icon: FcBriefcase,
    component: Projects,
  },
  {
    path: "/teams",
    name: "Teams",
    Icon: FcPositiveDynamic,
    component: Teams,
  },

  {
    path: "/weeklyperformance",
    name: "Weekly Performance",
    Icon: FcLineChart ,
    component: WeeklyPerformance,
  },
  {
    path: "/monthlyperformance",
    name: "Monthly Performance",
    Icon: FcPlanner ,
    component: MonthlyPerformance,
  },
  {
    path: "/nocasedownlines",
    name: "No Case Downlines",
    Icon: FcDislike ,
    component: RiskyDownlines ,
    primepath: true,
  },
  {
    path: "/noconvertdownlines",
    name: "No Convert Downlines",
    Icon: FcDislike ,
    component: NoConvertDownlines ,
    primepath: true,
  },
  // {
  //   path: "/pdreport",
  //   name: "PD Report",
  //   Icon: FcAcceptDatabase ,
  //   component: PDReport ,
  //   pdpath: true,
  // },
  // {
  //   path: "/sdreport",
  //   name: "SD Report",
  //   Icon: FcAcceptDatabase ,
  //   component: SDReport ,
  //   sdpath: true,
  // },
  // {
  //   path: "/coordinatorreport",
  //   name: "Coordinator Report",
  //   Icon: FcAcceptDatabase ,
  //   component: CoordinatorReport ,
  //   coordinatorpath: true,
  // },
  {
    path: "/reports",
    name: "Reports",
    Icon: FcAdvertising,
    component: DownlineReport,
  },
  {
    path: "/manualreports",
    name: "Manual Reports",
    Icon: FcAdvertising,
    component: ManualDownlineReport,
  },
  {
    path: "/salesrankingpercentage",
    name: "Top 30 Percentage",
    Icon: FcSalesPerformance,
    component: SalesRankingPercentage,
  },
  {
    path: "/regionsalesrankingpercentage",
    name: "Top 30 Percentage (State)",
    Icon: FcSalesPerformance,
    component: RegionSalesRankingPercentage,
  },
  {
    path: "/signedpercentage",
    name: "Incentive Percentage",
    Icon: FcBarChart ,
    component: SignedPercentage,
  },
  {
    path: "/cancelledpercentage",
    name: "Cancelled Percentage",
    Icon: FcStumbleupon ,
    component: CancelledPercentage,
  },

  {
    path: "/regioncancelledpercentage",
    name: "Cancelled Percentage (State)",
    Icon: FcStumbleupon ,
    component: RegionCancelledPercentage,
  },
  {
    path: "/cases",
    name: "Cases",
    Icon: FcIdea,
    component: Cases,
  },
  {
    path: "/whatsappdemo",
    name: "Whatsapp Demo",
    Icon: FcIdea,
    component: WhatsappDemo,
    hidden: true,
  },
  {
    path: "/paymentvouchers",
    name: "Payment Vouchers",
    Icon: FcDiploma2,
    component: PaymentVouchers,
  },
  {
    path: "/videos",
    name: "Videos",
    Icon: FcCamcorderPro,
    component: Videos,
  },
  {
    path: "/banners",
    name: "Awards",
    Icon: FcGraduationCap,
    component: Banners,
  },
  {
    path: "/campaigns",
    name: "News & Campaign",
    Icon: FcHighPriority,
    component: Campaigns,
  },
  {
    path: "/terms",
    name: "Terms",
    Icon: FcAbout,
    component: Terms,
  },
  {
    path: "/changepassword",
    name: "Change Password",
    Icon: FiImage,
    component: ChangePassword,
    hidden: true,
  },
  {
    path: "/editprofile",
    name: "Edit Profile",
    Icon: FiImage,
    component: EditProfile,
    hidden: true,
  },
  {
    path: "/chat",
    name: "Chatdaddy",
    Icon: FiImage,
    component: Chat,
    hidden: true,
  },
];


export default Routes;