import { gql } from "apollo-boost";

const queries = {
    LOGIN: gql`
        query Login($ic: String!, $password: String!) {
            login(input: { ic: $ic, password: $password }) {
                userId
                token
                name
                type
            }
        }
    `,
    CHANGE_PASSWORD: gql`
        mutation ChangePassword($password: String!, $currentPassword: String!) {
            changePassword(
                password: $password
                currentPassword: $currentPassword
            ) {
                _id
            }
        }
    `,
    EDIT_USER_PROFILE: gql`
        mutation EditUserProfile(
            $nickname: String
            $name: String
            $phone: String
            $bankDetails: String
        ) {
            editUserProfile(
                input: {
                    name: $name
                    nickname: $nickname
                    phone: $phone
                    bankDetails: $bankDetails
                }
            ) {
                _id
            }
        }
    `,
    CREATE_CHATS: gql`
    mutation CreateChats($chats: [CreateChatbotInput!]!) {
        createChatbot(chats: $chats) {
            _id
        }
    }
`,
FETCH_CHATS: gql`
query FetchChats {
    fetchChats {
        id
        connections {
            outputs {
                nodeId
                portName
            }
            inputs {
                nodeId
                portName
            }
        }
        inputData {
            Button
            Starter
        }
        type
        width
        x
        y
    }
}
`,
    FETCH_ALL_AREAS: gql`
        query FetchAllAreas {
            fetchAllAreas {
                _id
                name
            }
        }
    `,
    FETCH_ALL_STATES: gql`
        query FetchAllStates {
            fetchStates {
                _id
                name
            }
        }
    `,
    FETCH_MONTHLY_PERFORMANCE_AGENT: gql`
    query FetchMonthlyPerformanceAgent(
        $limit: Int!
        $page: Int!
        $by: String
        $dir: String
        $project: String
        $user: String
        $state: ID  
        $salesDirector: ID
        $year: String
    ) {
        fetchMonthlyPerformanceAgent(
            filter: { project: $project, user: $user, state: $state,    salesDirector: $salesDirector, year: $year }
            pagination: {
                limit: $limit
                page: $page
                sort: { by: $by, dir: $dir }
            }
        ) {
            data {
                month
                year
                pendingCases
                              cancelledCases
                rejectedCases
                convertedCases
                allCases
                conversionRate
                cancellationRate
                pendingRate
                rejectionRate
                allRate
            }
        }
    }
`,

FETCH_MONTHLY_SD_REPORT: gql`
query FetchMonthlySDReport(
    $limit: Int!
    $page: Int!
    $by: String
    $dir: String
    $project: String
    $user: String
    $state: ID  
    $salesDirector: ID
    $year: String
) {
    fetchMonthlySDReport(
        filter: { project: $project, user: $user, state: $state,    salesDirector: $salesDirector, year: $year }
        pagination: {
            limit: $limit
            page: $page
            sort: { by: $by, dir: $dir }
        }
    ) {
        data {
            month
            year
            pendingCases
                          cancelledCases
            rejectedCases
            convertedCases
            allCases
            conversionRate
            cancellationRate
            pendingRate
            rejectionRate
            allRate
        }
    }
}
`,

FETCH_MONTHLY_PD_REPORT: gql`
query FetchMonthlyPDReport(
    $limit: Int!
    $page: Int!
    $by: String
    $dir: String
    $project: String
    $user: String
    $state: ID  
    $salesDirector: ID
    $year: String
) {
    fetchMonthlyPDReport(
        filter: { project: $project, user: $user, state: $state,    salesDirector: $salesDirector, year: $year }
        pagination: {
            limit: $limit
            page: $page
            sort: { by: $by, dir: $dir }
        }
    ) {
        data {
            month
            year
            pendingCases
                          cancelledCases
            rejectedCases
            convertedCases
            allCases
            conversionRate
            cancellationRate
            pendingRate
            rejectionRate
            allRate
        }
    }
}
`,

FETCH_MONTHLY_COORDINATOR_REPORT: gql`
query FetchMonthlyCoordinatorReport(
    $limit: Int!
    $page: Int!
    $by: String
    $dir: String
    $project: String
    $user: String
    $state: ID  
    $salesDirector: ID
    $year: String
) {
    fetchMonthlyCoordinatorReport(
        filter: { project: $project, user: $user, state: $state,    salesDirector: $salesDirector, year: $year }
        pagination: {
            limit: $limit
            page: $page
            sort: { by: $by, dir: $dir }
        }
    ) {
        data {
            month
            year
            pendingCases
                          cancelledCases
            rejectedCases
            convertedCases
            allCases
            conversionRate
            cancellationRate
            pendingRate
            rejectionRate
            allRate
        }
    }
}
`,

FETCH_WEEKLY_PERFORMANCE_AGENT: gql`
query FetchWeeklyPerformanceAgent(
    $limit: Int!
    $page: Int!
    $by: String
    $dir: String
    $project: String
    $user: String
    $state: ID  
    $salesDirector: ID
    $year: String
    $month: String
) {
    fetchWeeklyPerformanceAgent(
        filter: { project: $project, user: $user, state: $state,    salesDirector: $salesDirector, year: $year, month: $month }
        pagination: {
            limit: $limit
            page: $page
            sort: { by: $by, dir: $dir }
        }
    ) {
        data {
            week
            month
            year
            pendingCases
            cancelledCases
            rejectedCases
            convertedCases
            allCases
            conversionRate
            cancellationRate
            pendingRate
            rejectionRate
            allRate
        }
    }
}
`,

    FETCH_PROJECTS: gql`
        query FetchProjects(
            $limit: Int!
            $page: Int!
            $state: String
            $area: String
            $project: String
        ) {
            fetchProjectsAgent(
                pagination: { limit: $limit, page: $page }
                filters: { state: $state, area: $area, project: $project }
            ) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                }
                data {
                    _id
                    name
                    image
                    picName
                    picPhone
                    directorName
                    directorPhone
                    coordinatorName
                    coordinatorPhone
                    area {
                        _id
                        name
                    }
                    developer
                    status
                    type
                    minBedRoom
                    maxBedRoom
                    minBathRoom
                    maxBathRoom
                    unit
                    minSqft
                    maxSqft
                    completionDate
                    state {
                        name
                    }
                    salesDirector {
                        name
                    }
                    projectDirector {
                        name
                    }
                    attributes {
                        attribute
                        value
                    }
                    saleskit
                    hold
                    info {
                        projectLocation
                        projectUnits
                        projectBlocks
                        projectType
                        projectSalesPackage
                        projectCommPercentage
                        projectTelegram
                        projectGallery {
                            _id
                            url
                            title
                        }
                    }
                }
            }
        }
    `,
    FETCH_TOP_30: gql`
        query FetchTop30s(
            $dateFrom: String
            $dateTo: String
            $state: String
            $type: String
        ) {
            fetchTop30s(
                filter: {
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                    state: $state
                    type: $type
                }
            ) {
                data {
                    top30 {
                        member {
                            name
                            nickname
                        }
                        position
                        commission
                    }
                   date
                   type
                }
            }
        }
    `,
    FETCH_VIDEOS: gql`
        query FetchVideos($limit: Int!, $page: Int!) {
            fetchVideos(pagination: { limit: $limit, page: $page }) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                }
                data {
                    _id
                    name
                    url
                }
            }
        }
    `,
    FETCH_BANNERS_AGENT: gql`
        query FetchBannersAgent($state: String!, $type: String!, $isMobile: Boolean!) {
            fetchBannersAgent(filter: { state: $state, type: $type, isMobile: $isMobile }) {
                _id
                name
                url
                type
            }
        }
    `,
    FETCH_CAMPAIGNS_AGENT: gql`
        query FetchCampaignsAgent {
            fetchCampaignsAgent {
                _id
                name
                url
                type
                enNotesArray
                cnNotesArray
            }
        }
    `,
    FETCH_CASES_SALES_RANKING: gql`
        query FetchCasesSalesRanking(
            $limit: Int!
            $page: Int!
            $project: ID
            $date: String
            $dateFrom: String
            $dateTo: String
            $rank: ID
            $state: ID
            $status: String
        ) {
            fetchCasesSalesRanking(
                pagination: { limit: $limit, page: $page }
                filter: {
                    project: $project
                    date: $date
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                    status: $status
                    rank: $rank
                    sate: $state
                }
            ) {
                pagination {
                    documents
                    page
                    limit
                    totalPages
                }
                data {
                    ranking
                    user
                    commission
                }
            }
        }
    `,
    WHOAMI: gql`
        query Whoami {
            whoami {
                image
                name
                nickname
                ranking {
                    _id
                    name
                }
                ic
                phone
                bankDetails
                code
                customRank
                cp58
                personalSales
                groupSales
                referral {
                    name
                }
                state {
                    name
                }
                email
                director
                omcDate
                emcDate
                pmcDate
                smcDate
                progression
            }
        }
    `,
    FETCH_USER_PAYMENT_VOUCHERS: gql`
        query FetchUserPaymentVouchers(
            $limit: Int!
            $page: Int!
            $dateFrom: String
            $dateTo: String
            $calculatePoints: String
        ) {
            fetchUserPaymentVouchers(
                pagination: { limit: $limit, page: $page }
                filters: { dateFrom: $dateFrom, dateTo: $dateTo, calculatePoints: $calculatePoints}
            ) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                }
                data {
                    _id
                    payee {
                        _id
                        name
                        nickname
                        code
                    }
                    date
                    method
                    batch
                    status
                    calculatePoints
                    item {
                        amount
                        address
                    }
                    item2 {
                        amount
                        address
                    }
                    item3 {
                        amount
                        address
                    }
                    item4 {
                        amount
                        address
                    }
                    item5 {
                        amount
                        address
                    }
                    orderNo
                }
                totals {
                    totalPaymentVoucher
                    totalVoucherAmount
                }
            }
        }
    `,
    FETCH_CASES_REPORT: gql`
        query FetchCasesAgent(
            $limit: Int!
            $page: Int!
            $by: String
            $dir: String
            $project: ID
            $date: String
            $dateFrom: String
            $dateTo: String
            $status: String
            $downline: ID
        ) {
            fetchCasesAgent(
                pagination: {
                    limit: $limit
                    page: $page
                    sort: { by: $by, dir: $dir }
                }
                filter: {
                    project: $project
                    date: $date
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                    status: $status
                    downline: $downline
                }
            ) {
                totals {
                    cases
                    spaPrice
                    netPrice
                    totalNetGdv
                    totalCommission
                    totalCommissionPercentage
                    totalPrimeLeaderComm
                    totalLeaderComm
                    totalPtpReferralComm
                    totalPtp2ReferralComm
                    totalPtp1ReferralComm
                    totalGroupNetGdv
                }
                pagination {
                    documents
                    totalPages
                    page
                    limit
                    by
                    dir
                }
                data {
                    _id
                    members {
                        _id
                        commission
                        newCommission
                        member {
                            _id
                            name
                        }
                        memberCommStatus {
                            fastComm
                            firstBatchComm
                            secondBatchComm
                            fullClaimComm
                            revisedComm
                        }
                    }
                    image
                    salesKit
                    spaPrice
                    rebate
                    fees
                    cashback
                    netPrice
                    status
                    bookingDate
                    convertDate
                    unitNumber
                    sponsorCommissions {
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                    }
                    leaderCommissions {
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                    }
                    purchaser {
                        purchaserName
                        purchaserName2
                        purchaserName3
                        purchaserName4
                        purchaserName5
                        purchaserIc
                        purchaserIc2
                        purchaserIc3
                        purchaserIc4
                        purchaserIc5
                        purchaserContact
                        purchaserContact2
                        purchaserContact3
                        purchaserContact4
                        purchaserContact5
                    }
                    createdBy {
                        _id
                        name
                    }
                    project {
                        _id
                        name
                    }
                    worker
                }
            }
        }
    `,
    FETCH_EVENTS_AGENT: gql`
        query FetchEventsAgent {
            fetchEventsAgent {
                start
                end
                title
                start_time
                end_time
                location
                image
            }
        }
    `,
    FETCH_DOWNLINES: gql`
        query FetchDownlines(
            $limit: Int!
            $page: Int!
            $name: String
            $referral: String
            $status: String
            $rank: ID!
        ) {
            fetchDownlines(
                filters: { name: $name, referral: $referral, status: $status,                     rank: $rank }
                pagination: {
                    limit: $limit
                    page: $page
                }
            ) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                }
                totals {
                    personalSalesTotal
                    groupSalesTotal
                }
                uplineFilterList {
                    value
                    label
                    id
                }
                data {
                    _id
                    status
                    name
                    dob
                    personalSales
                    groupSales
                    progression
                    ranking {
                        _id
                        name
                    }
                    referral {
                        _id
                        name
                    }
                    secondReferral {
                        _id
                        name
                    }
                }
            }
        }
    `,
    FETCH_RISKY_DOWNLINES: gql`
    query FetchRiskyDownlines(
        $limit: Int!
        $page: Int!
        $name: String
        $referral: String
        $status: String
        $rank: ID!
    ) {
        fetchRiskyDownlines(
            filters: { name: $name, referral: $referral, status: $status, rank: $rank }
            pagination: {
                limit: $limit
                page: $page
            }
        ) {
            pagination {
                documents
                totalPages
                page
                limit
            }
            totals {
                personalSalesTotal
                groupSalesTotal
            }
            uplineFilterList {
                value
                label
                id
            }
            data {
                _id
                status
                name
                personalSales
                groupSales
                risk
                noConvert
                progression
                ranking {
                    _id
                    name
                }
                referral {
                    _id
                    name
                }
                secondReferral {
                    _id
                    name
                }
            }
        }
    }
`,
FETCH_NO_CONVERT_DOWNLINES: gql`
query FetchNoConvertDownlines(
    $limit: Int!
    $page: Int!
    $name: String
    $referral: String
    $status: String
    $rank: ID!
) {
    fetchNoConvertDownlines(
        filters: { name: $name, referral: $referral, status: $status, rank: $rank }
        pagination: {
            limit: $limit
            page: $page
        }
    ) {
        pagination {
            documents
            totalPages
            page
            limit
        }
        totals {
            personalSalesTotal
            groupSalesTotal
        }
        uplineFilterList {
            value
            label
            id
        }
        data {
            _id
            status
            name
            personalSales
            groupSales
            risk
            noConvert
            progression
            ranking {
                _id
                name
            }
            referral {
                _id
                name
            }
            secondReferral {
                _id
                name
            }
        }
    }
}
`,
    FETCH_DOWNLINE_SALES_REPORT_AGENT: gql`
    query FetchDownlineSalesReportAgent($dateTo: String, $dateFrom: String, $month: String, $year: String) {
        fetchDownlineSalesReportAgent(filter: { dateTo: $dateTo, dateFrom: $dateFrom, month: $month, year: $year }) {
            data {
                user
                personalBookingSales
                convertUnit
                convertPercentage
                personalNetGdv
                convertNetGdv
                netGdvPercentage
                groupBookingSales
                groupConvertUnit
                groupConvertPercentage
                groupPersonalNetGdv
                groupConvertNetGdv
                groupNetGdvPercentage
                salesRankingCommission
            }
        }
    }
`,
FETCH_MANUAL_DOWNLINE_SALES_REPORT_AGENT: gql`
query FetchManualDownlineSalesReportAgent($dateTo: String, $dateFrom: String) {
    fetchManualDownlineSalesReportAgent(filter: { dateTo: $dateTo, dateFrom: $dateFrom }) {
        data {
            user
            personalBookingSales
            convertUnit
            convertPercentage
            personalNetGdv
            convertNetGdv
            netGdvPercentage
            groupBookingSales
            groupConvertUnit
            groupConvertPercentage
            groupPersonalNetGdv
            groupConvertNetGdv
            groupNetGdvPercentage
            salesRankingCommission
        }
    }
}
`,
GENERATE_IK_SIGNATURE: gql`
query {
    generateImageKitSignature {
        signature
        token
        expire
    }
}
`,
};

export default queries