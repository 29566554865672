import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";
import { UserProvider, UserConsumer } from "./Contexts/user";
import Login from "./Pages/Login";
import Layout from "./Components/layout";
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "@apollo/react-hooks";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import Routes from "./Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Report from "./Pages/Report";
import ProjectInfo from "./Pages/ProjectInfo";
import Saleskit from "./Pages/Saleskit";
import { PDFViewer } from "@react-pdf/renderer";

let BASE_URL = undefined;
if (process.env.NODE_ENV === "production") {
    if (process.env.REACT_APP_ORGANISATION === "DND") {
        BASE_URL = "https://skymirrorholiday.com";
    } else if (process.env.REACT_APP_ORGANISATION === "KLT") {
        BASE_URL = "https://kltlabsproperty.online";
    }
    var httpLink = createHttpLink({
        uri: BASE_URL,
    });
} else if (process.env.NODE_ENV === "development") {
    var developmentLink = createHttpLink({
        uri: "http://localhost:5000",
    });
}

const authLink = setContext((_, { headers }) => {
    const authObj =
        JSON.parse(localStorage.getItem("auth")) ||
        JSON.parse(sessionStorage.getItem("auth"));

    return {
        headers: {
            ...headers,
            authorization: authObj ? `Bearer ${authObj.token}` : "",
        },
    };
});

const client = new ApolloClient({
    link: httpLink
        ? authLink.concat(httpLink)
        : authLink.concat(developmentLink),
    cache: new InMemoryCache(),
});

const App = () => {
    return (
        <ApolloProvider client={client}>
            <UserProvider>
                <UserConsumer>
                    {(user) => {
                        const AuthRoute = ({
                            component: Component,
                            ...rest
                        }) => {
                            return (
                                <Route
                                    {...rest}
                                    render={(props) =>
                                        !user.auth.token ? (
                                            <Component {...props} />
                                        ) : (
                                            <Redirect
                                                to={{
                                                    pathname: "/",
                                                    state: {
                                                        from: props.location,
                                                    },
                                                }}
                                            />
                                        )
                                    }
                                />
                            );
                        };

                        const PrivateRoute = ({
                            component: Component,
                            ...rest
                        }) => {
                            return (
                                <Route
                                    {...rest}
                                    render={(props) =>
                                        user.auth.token ? (
                                            <Component {...props} />
                                        ) : (
                                            <Redirect
                                                to={{
                                                    pathname: "/login",
                                                    state: {
                                                        from: props.location,
                                                    },
                                                }}
                                            />
                                        )
                                    }
                                />
                            );
                        };

                        return (
                            <Router>
                                {user.auth.loaded && (
                                    <Switch>
                                        <AuthRoute
                                            path="/login"
                                            component={Login}
                                            exact
                                        />
                                        <Route path="/report" exact>
                                            {({ location }) => {
                                                return (
                                                    <div className="report-container">
                                                        <PDFViewer>
                                                            <Report
                                                                data={
                                                                    location.state
                                                                }
                                                            />
                                                        </PDFViewer>
                                                    </div>
                                                );
                                            }}
                                        </Route>
                                        <Layout>
                                            <PrivateRoute exact path="/projects/saleskit/:project">
                                                <div className="relative">
                                                    <Saleskit />
                                                </div>
                                            </PrivateRoute>
                                            {Routes.map((route, i) => (
                                                <PrivateRoute
                                                    exact
                                                    path={route.path}
                                                    component={route.component}
                                                    key={route.name}
                                                />
                                            ))}
                                            <PrivateRoute exact path="/projects/:project">
                                                <div className="relative">
                                                    <ProjectInfo />
                                                </div>
                                            </PrivateRoute>
                                        </Layout>
                                        <Redirect to="/" />
                                    </Switch>
                                )}
                            </Router>
                        );
                    }}
                </UserConsumer>
                <ToastContainer />
            </UserProvider>
        </ApolloProvider>
    );
};

export default App;
