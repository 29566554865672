import React, { useEffect } from "react";
import { NodeEditor } from "flume";
import { FlumeConfig, Colors, Controls } from "flume";
import { useMutation, useQuery } from "@apollo/react-hooks";
import axios from "axios";
import queries from "../helpers/queries";
import { useApolloClient } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import { withUser } from "../Contexts/user";

let runningIndex = 1;
let image = [];

const Chat = withRouter(({ user }) => {
    const apollo = useApolloClient();
    const [tests, setTests] = React.useState(undefined);
    // eslint-disable-next-line
    const [nodes, setNodes] = React.useState([]);
    const [createChats] = useMutation(queries.CREATE_CHATS);

    console.log(nodes)

    const nodeEditor = React.useRef({ disableComments: true });

    // const formattedNumber = user
    //     ? user.auth
    //         ? user.auth.phone.replace(/\D/g, "")
    //         : ""
    //     : "";

    // const cleanedNumber = `6${formattedNumber}@s.whatsapp.net`;
    const cleanedNumber = `60102858105:23@s.whatsapp.net`;

    useEffect(() => {
        if (image[0] === undefined && tests) {
            const generateSignature = async () => {
                const ikSigReq = await apollo.query({
                    query: queries.GENERATE_IK_SIGNATURE,
                    fetchPolicy: "no-cache",
                });

                return ikSigReq;
            };

            const fetchData = async () => {
                const imageresult = await generateSignature();
                const imageFormData = new FormData();

                imageFormData.append("file", tests);
                imageFormData.append(
                    "publicKey",
                    "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
                );
                imageFormData.append(
                    "signature",
                    imageresult.data.generateImageKitSignature.signature
                );
                imageFormData.append(
                    "expire",
                    imageresult.data.generateImageKitSignature.expire
                );
                imageFormData.append(
                    "token",
                    imageresult.data.generateImageKitSignature.token
                );
                imageFormData.append(
                    "fileName",
                    `${tests.name} - ${imageresult.data.generateImageKitSignature.expire}`
                );
                imageFormData.append("folder", "projects");

                const imageUploadResult = await axios.post(
                    "https://upload.imagekit.io/api/v1/files/upload",
                    imageFormData
                );

                // const temporaryImage = {
                //     code: `${user ? user.auth.code : "NONE"}-${runningIndex}`,
                //     image: "www.abc.com",
                // };

                const temporaryImage = {
                    code: `${user ? user.auth.code : "NONE"}-${runningIndex}`,
                    image: imageUploadResult.data.url,
                };
                image.push(temporaryImage);
                runningIndex = runningIndex + 1;
                // return imageUploadResult
                return image;
                // ...
            };
            fetchData();
        }
    }, [apollo, tests, user]);

    const config = new FlumeConfig();

    config
        .addPortType({
            type: "string",
            name: "string",
            label: "Text",
            color: Colors.green,
            controls: [
                Controls.text({
                    name: "string",
                    label: "Text",
                }),
            ],
        })
        .addPortType({
            type: "button",
            name: "Button",
            label: "Button",
            color: Colors.green,
            controls: [
                Controls.text({
                    name: "string",
                    label: "Button",
                }),
            ],
        })
        .addPortType({
            type: "boolean",
            name: "boolean",
            label: "True/False",
            color: Colors.blue,
            controls: [
                Controls.checkbox({
                    name: "boolean",
                    label: "True/False",
                }),
            ],
        })
        .addPortType({
            type: "number",
            name: "number",
            label: "Number",
            color: Colors.red,
            controls: [
                Controls.number({
                    name: "number",
                    label: "Number",
                }),
            ],
        })
        .addPortType({
            type: "uppercaseText",
            name: "uppercaseText",
            label: "Uppercase Text",
            controls: [
                Controls.text({
                    label: "Uppercase Text",
                    setValue: (newData, oldData) => console.log(...newData),
                }),
            ],
        })
        .addPortType({
            type: "image",
            name: "image",
            label: "Image",
            color: Colors.pink,
            controls: [
                Controls.custom({
                    name: "image",
                    label: "Image",
                    render: () => {
                        return (
                            <>
                                <div className="my-2 text-sm text-lynch font-medium">
                                    Image
                                </div>
                                <input
                                    className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs border-gray-300
                                        `}
                                    name="image"
                                    type="file"
                                    key="image"
                                    onChange={(e) => {
                                        setTests(e.target.files[0]);
                                    }}
                                />
                            </>
                        );
                    },
                }),
            ],
        })

        .addNodeType({
            type: "string",
            label: "Text",
            description: "Outputs a string of text",
            inputs: (ports) => [ports.string()],
            outputs: (ports) => [ports.string()],
        })

        // .addNodeType({
        //     type: "boolean",
        //     label: "True/False",
        //     description: "Outputs a true/false value",
        //     initialWidth: 140,
        //     inputs: (ports) => [ports.boolean()],
        //     outputs: (ports) => [ports.boolean()],
        // })
        .addNodeType({
            type: "location",
            label: "Location",
            description: "Outputs a numeric value",
            initialWidth: 160,
            inputs: (ports) => [
                ports.number({
                    name: "longitude",
                    label: "Longitude",
                }),
                ports.number({
                    name: "latitude",
                    label: "Latitude",
                }),
                ports.string({
                    name: "location",
                    label: "Location",
                }),
            ],
            outputs: (ports) => [
                ports.string({
                    name: "location",
                    label: "Location",
                }),
            ],
        })
        // .addNodeType({
        //     type: "poll",
        //     label: "Poll",
        //     initialWidth: 170,
        //     inputs: (ports) => [
        //         ports.string({
        //             name: "Title",
        //             label: "Title",
        //         }),
        //     ],
        //     outputs: (ports) => [
        //         ports.string({
        //             name: "Title",
        //             label: "Title",
        //         }),
        //     ],
        // })
        .addNodeType({
            type: "button",
            label: "Button",
            description: "Outputs a string of text",
            inputs: (ports) => (data) => {
                return [
                    ports.string({
                        name: "Button",
                        label: data.Button ? data.Button.string : {},
                    }),
                    ports.string({
                        name: "ChineseButton",
                        label: data.ChineseButton ? data.ChineseButton.string : {},
                    }),
                ];
            },
            outputs: (ports) => (data) => {
                return [
                    ports.string({
                        name: "Button",
                        label: data.Button ? data.Button.string : {},
                    }),
                ];
            },
        })
        .addNodeType({
            type: "image",
            label: "Image",
            description: "Outputs a shader",
            initialWidth: 300,
            inputs: (ports) => (data) => {
                return [
                    ports.string({
                        name: `${
                            user ? user.auth.code : "NONE"
                        }-${runningIndex}`,
                        value: `${
                            user ? user.auth.code : "NONE"
                        }-${runningIndex}`,
                    }),
                    ports.image({ name: `url`, label: "None" }),
                ];
            },
            outputs: (ports) => [
                ports.string({
                    name: "Button",
                    label: `${user ? user.auth.code : "NONE"}-${runningIndex}`,
                }),
            ],
        })
        // .addNodeType({
        //     type: "calculateTotal",
        //     label: "Calculate Total",
        //     description: "Calculates the total price of the checkout",
        //     inputs: ports => [
        //       ports.number({ name: "subtotal", label: "Subtotal"}),
        //       ports.number({ name: "taxRate", label: "Tax Rate"}),
        //       ports.boolean({ name: "isTaxExempt", label: "Is Tax-Exempt"})
        //     ],
        //     outputs: ports => [
        //       ports.number({ name: "total", label: "Total"})
        //     ]
        //   })
        .addRootNodeType({
            type: "starter",
            label: "Starter",
            color: Colors.pink,
            initialWidth: 170,
            inputs: (ports) => [
                ports.string({
                    name: "Starter",
                    label: "Starter",
                }),
            ],
            outputs: (ports) => [
                ports.string({
                    name: "Starter",
                    label: "Starter",
                }),
            ],
        });

    const saveNodes = async () => {
        let payload = [];
        const nodes = nodeEditor.current.getNodes();
        const nodesArray = Object.values(nodes);

        for (const item of nodesArray) {
            if (item.type === "starter") {
                payload.push({
                    type: item.type,
                    node_id: `${
                        user ? (user.auth ? user.auth.code : "") : ""
                    }-${item.id}`,
                    nextFlow: item.connections
                        ? item.connections.outputs
                            ? item.connections.outputs.Starter
                                ? item.connections.outputs.Starter.map(
                                      (input) =>
                                          `${
                                              user
                                                  ? user.auth
                                                      ? user.auth.code
                                                      : ""
                                                  : ""
                                          }-${input.nodeId}`
                                  )
                                : []
                            : []
                        : [],
                    title: item.inputData.Starter.string,
                    message: item.inputData.Starter.string,
                    chatbotNumber: cleanedNumber,
                    createdBy: user ? user.auth ? user.auth.userId : undefined : undefined
                });
            } else if (item.type === "button") {
                payload.push({
                    type: "poll",
                    node_id: `${
                        user ? (user.auth ? user.auth.code : "") : ""
                    }-${item.id}`,
                    nextFlow: item.connections
                        ? item.connections.outputs
                            ? item.connections.outputs.Button
                                ? item.connections.outputs.Button.map(
                                      (input) =>
                                          `${
                                              user
                                                  ? user.auth
                                                      ? user.auth.code
                                                      : ""
                                                  : ""
                                          }-${input.nodeId}`
                                  )
                                : []
                            : []
                        : [],
                    title: item.inputData.Button.string,
                    cnTitle: item.inputData.ChineseButton.string,
                    message: item.inputData.Button.string,
                    chatbotNumber: cleanedNumber,
                    createdBy: user ? user.auth ? user.auth.userId : undefined : undefined
                });
            } else if (item.type === "image") {
                const object = item.inputData;
                const key = Object.keys(object)[0];
                const selectedImage = await image.find((img) => img.code === key);


                payload.push({
                    type: item.type,
                    node_id: `${
                        user ? (user.auth ? user.auth.code : "") : ""
                    }-${item.id}`,
                    nextFlow: item.connections
                        ? item.connections.outputs
                            ? item.connections.outputs.Button
                                ? item.connections.outputs.Button.map(
                                      (input) =>
                                          `${
                                              user
                                                  ? user.auth
                                                      ? user.auth.code
                                                      : ""
                                                  : ""
                                          }-${input.nodeId}`
                                  )
                                : []
                            : []
                        : [],
                    message: `${
                        user ? (user.auth ? user.auth.code : "") : ""
                    }-${item.id}`,
                    title: `${user ? (user.auth ? user.auth.code : "") : ""}-${
                        item.id
                    }`,
                    chatbotNumber: cleanedNumber,
                    attachment: selectedImage ? selectedImage.image : "",
                    createdBy: user ? user.auth ? user.auth.userId : undefined : undefined
                });
            } else if (item.type === "string") {
                payload.push({
                    type: item.type,
                    node_id: `${
                        user ? (user.auth ? user.auth.code : "") : ""
                    }-${item.id}`,
                    nextFlow: item.connections
                        ? item.connections.outputs
                            ? item.connections.outputs.string
                                ? item.connections.outputs.string.map(
                                      (input) =>
                                          `${
                                              user
                                                  ? user.auth
                                                      ? user.auth.code
                                                      : ""
                                                  : ""
                                          }-${input.nodeId}`
                                  )
                                : []
                            : []
                        : [],
                    title: item.inputData.string.string,
                    message: item.inputData.string.string,
                    chatbotNumber: cleanedNumber,
                    createdBy: user ? user.auth ? user.auth.userId : undefined : undefined
                });
            }
        }
        
        const result = await createChats({
            variables: {
                chats: payload
            },
          });
        console.log(result);
    };

    useQuery(queries.FETCH_CHATS, {
        fetchPolicy: "network-only",
        onCompleted: ({
            fetchChats: { pagination, ...fetchChats },
        }) => {
            console.log(fetchChats)
        },
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                for (const graphQLError of graphQLErrors) {
                    console.log(graphQLError);
                }
            }

            if (networkError) {
                console.log(networkError);
            }
        },
    });

    React.useCallback((nodes) => {
        // Do whatever you want with the nodes
        setNodes(nodes);
    }, []);

    return (
        <>
            <button onClick={saveNodes}>Save Logic</button>
            <button onClick={() => console.log(image)}>ABC</button>
            <div
                style={{ width: window.innerWidth, height: window.innerHeight }}
            >
                <NodeEditor
                    ref={nodeEditor}
                    portTypes={config.portTypes}
                    nodeTypes={config.nodeTypes}
                    disableComments={true}
                    // nodes={nodes}
                    // onChange={setNodes}
                    defaultNodes={[
                        {
                            type: "starter",
                            x: -250,
                            y: -150,
                        },
                        {
                            type: "button",
                            x: -50,
                            y: 50,
                        },
                    ]}
                />
            </div>
        </>
    );
});

export default withUser(Chat);
