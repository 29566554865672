const Terms = () => {
    const emc = [
        "https://ik.imagekit.io/dnddecpho/dndream/D&D_NewWebsiteIcon-05_1Crm5OU_F.png?updatedAt=1705479725122",
        "https://ik.imagekit.io/dnddecpho/dndream/D&D_NewWebsiteIcon-06_NFhgKdZLk.png?updatedAt=1705479724841",
        "https://ik.imagekit.io/dnddecpho/dndream/D&D_NewWebsiteIcon-07_ndLRVTUJj.png?updatedAt=1705479724449",
        "https://ik.imagekit.io/dnddecpho/dndream/D&D_NewWebsiteIcon-08_wZM8C61PP.png?updatedAt=1705479724830",
    ];

    return (
        <div className="tnc-background overflow-hidden text-white">
            <div className="greyOpaqueBackground lg:pt-8 pt-0 py-8 pb-16 lg:px-64 ">
                <div
                    className="lg:px-3  lg:mb-8 my-3 py-2 lg:pb-8 font-black lg:text-4xl text-xl text-center tracking-tighter uppercase"
                    style={{ fontFamily: "Montserrat-Black" }}
                >
                    <div
                        className=" text-white py-6 lg:text-4xl text-3xl mx-auto tracking-wide leading-10 max-w-5xl px-8"
                        style={{ fontFamily: "Montserrat-Regular" }}
                    >
                        ⚠ 注意事件 ⚠
                    </div>
                </div>

                <div className="lg:my-8 lg:gap-8 gap-2 my-1 grid grid-cols-2 lg:grid-cols-4">
                    {emc.map((imageUrl, index) => (
                        <img
                            key={index}
                            src={imageUrl}
                            className="productImg"
                            alt="productImages"
                        />
                    ))}
                </div>

                <div className="flex justify-center items-center py-4 lg:h-60vh">
                    <div className=" tracking-wider">
                        <div
                            className="lg:px-3 px-6 font-black text-white lg:text-3xl text-2xl py-4 uppercase "
                            style={{ fontFamily: "Montserrat-Black" }}
                        >
                            近期里，公司陆陆续续收到不一样的发展商的投诉和律师信关于我们的伙伴在广告上面用到他们的名字和商标！
                        </div>

                        <div
                            className="lg:px-3 px-6 font-black text-white lg:text-3xl text-2xl py-4 uppercase "
                            style={{ fontFamily: "Montserrat-Black" }}
                        >
                            所有有在广告上放 “Desa Park City” & ”Sime Darby”
                            的字眼或则 Logo的一律马上关掉并且删除⚠⚠⚠
                        </div>

                        <div
                            className="lg:px-3 px-6 font-black text-white lg:text-3xl text-2xl py-4 uppercase "
                            style={{ fontFamily: "Montserrat-Black" }}
                        >
                            在这里公司需要再次强调给所有的伙伴们知道关于广告的问题：
                        </div>
                        <div
                            className="text-white py-6 lg:text-2xl mx-auto lg:leading-10 tracking-wide leading-10 px-8"
                            style={{ fontFamily: "Montserrat-Regular" }}
                        >
                            <div>❌所有的广告都不能放发展商的名字</div>
                            <div>❌不能放其他发展商的名字在设计图</div>
                            <div>❌不可以放有Landmark的Logo</div>
                            <div>❌不可以用发展商的照片/facilities</div>
                            <div>❌不可以用发展商的showroom video</div>
                        </div>
                        <div
                            className="lg:px-3 px-6 font-black text-white lg:text-3xl text-2xl py-4 uppercase "
                            style={{ fontFamily: "Montserrat-Black" }}
                        >
                            如若公司看到或者再次收到投诉和律师信，公司将会采取严厉的行动！该伙伴除了需要承担发展商的法律责任和所有的费用，并且公司将会保留追究的权利！
                        </div>
                        <div
                            className="lg:px-3 px-6 font-black text-white lg:text-3xl text-2xl py-4 uppercase "
                            style={{ fontFamily: "Montserrat-Black" }}
                        >
                            谢谢
                        </div>
                        <div
                            className="lg:px-3 px-6 font-black text-white lg:text-3xl text-2xl py-4 uppercase "
                            style={{ fontFamily: "Montserrat-Black" }}
                        >
                            - D&D Management
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Terms;
