import React from "react";
import { Carousel } from "react-responsive-carousel";
import "../override.css";

const Slider = ({ data }) => {
  
    const background = data ? data[0] ? data[0].backgroundclass : "" : ""
    console.log(background)
    return (
        <div className="">
            <Carousel autoPlay showThumbs={false}>
                {data.map((item, i) => (
                    <div key={i}>
<div className={`${background} `}>
    <div className="opaqueBackground h-screen lg:p-8 md:justify-center h-full lg:grid grid-cols-3 gap-6 hidden lg:block">

                            <img
                                className={`${
                                    item.type === "airpod"
                                        ? "default-image"
                                        : "small-banner-image"
                                } lg:m-auto`}
                                alt=""
                                src={item[0].url}
                            />
                            <img
                                className={`${
                                    item.type === "airpod"
                                        ? "default-image"
                                        : "small-banner-image"
                                } lg:m-auto`}
                                alt=""
                                src={item[1] ? item[1].url : ""}
                            />
                            <img
                                className={`${
                                    item.type === "airpod"
                                        ? "default-image"
                                        : "small-banner-image"
                                } lg:m-auto`}
                                alt=""
                                src={item[2] ? item[2].url : ""}
                            />
                                                        <img
                                className={`${
                                    item.type === "airpod"
                                        ? "default-image"
                                        : "small-banner-image"
                                } lg:m-auto`}
                                alt=""
                                src={item[3] ? item[3].url : ""}
                            />
                            <img
                                className={`${
                                    item.type === "airpod"
                                        ? "default-image"
                                        : "small-banner-image"
                                } lg:m-auto`}
                                alt=""
                                src={item[4] ? item[4].url : ""}
                            />
                            <img
                                className={`${
                                    item.type === "airpod"
                                        ? "default-image"
                                        : "small-banner-image"
                                } lg:m-auto`}
                                alt=""
                                src={item[5] ? item[5].url : ""}
                            />
                        </div>
                        <div className="h-80vh lg:p-8 md:justify-center h-full lg:grid grid-cols-3 gap-6 lg:hidden flex justify-center items-center">
                            <img
                                className={`default-image lg:m-auto`}
                                alt=""
                                src={item[0].url}
                            />
                        </div>
                    </div>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default Slider;
