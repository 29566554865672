import React from "react";
import {

    FcVip,

} from "react-icons/fc";
import { withUser } from "../Contexts/user";

const Dashboard = () => {

    const axios = require("axios");

    const url = "https://graph.facebook.com/v19.0/316954361496592/messages";
    const headers = {
        Authorization:
            "Bearer EAAGFEs5KSAsBO1uVUICLpRhLGYwxOGUhOsOZC41GllnFBEu82VkXSrV5bpf05WXfZBA2ufFdCbGLHrilMhQZALSB66YgSzRr68xhzZAZC8TU5CL8ZATNigk4NC0qrZC30ZCTPv0oiwZCPhuDwuSjIZBJ14UzCF4E4Aa22CAiCe8xV2t36f1tpZBPjrERlQKPGMWZCl1Y8gZDZD",
        "Content-Type": "application/json",
    };
    const sendWhatsappTemplate = async (templateData) => {
        try {
            const response = await axios.post(url, templateData, { headers });
            console.log("Response:", response.data);
        } catch (error) {
            console.error("Error:", error.response.data);
        }
    };
    
    const createTemplateData = (name, parameters) => ({
        messaging_product: "whatsapp",
        to: "60146308816",
        type: "template",
        template: {
            name: name,
            language: {
                code: "en_US",
            },
            components: [
                {
                    type: "body",
                    parameters: parameters.map((text) => ({
                        type: "text",
                        text: text,
                    })),
                },
            ],
        },
    });
    
    const paymentvoucherdata = createTemplateData("payment_voucher", [
        "Kelvin Test",
        "500",
        "14-05-2024",
        "JXC2024",
        "RAZA CONDO B-02-04",
    ]);
    
    const riskydownlinedata = createTemplateData("risky_downline", [
        "Tan Tiong Ten",
        "3 weeks",
    ]);
    
    const noconvertdownlinedata = createTemplateData("noconvert_downline", [
        "Tan Tiong Ten",
        "2 months",
    ]);

    const newmemberdata = createTemplateData("new_member", [
        "Tan Tiong Ten",
        "Sheng Shoo Ting",
        "Kelvin Tan",
        "Mr Primeleader"
    ]);

    const weeklyreportdata = createTemplateData("weekly_report", [
       "15-05-2024",
        "350",
        "56",
        "625023",
        "888695",
        "25",
        "77"
    ]);

    const monthlyreportdata = createTemplateData("monthly_report", [
        "01",
         "2024",
         "667",
         "238",
         "35.68",
         "345",
         "51.72",
         "0",
         "0.00",
         "84",
         "12.59"
     ]);
    
    const handlePaymentVoucher = () => sendWhatsappTemplate(paymentvoucherdata);
    const handleRiskyDownline = () => sendWhatsappTemplate(riskydownlinedata);
    const handleNoConvertDownline = () => sendWhatsappTemplate(noconvertdownlinedata);
    const handleNewMember = () => sendWhatsappTemplate(newmemberdata);
    const handleWeeklyReport = () => sendWhatsappTemplate(weeklyreportdata);
    const handleMonthlyReport = () => sendWhatsappTemplate(monthlyreportdata);

    return (
        <div className="flex h-full tnc-background bg-black" >
            <div className="greyOpaqueBackground flex flex-wrap rounded-md w-full overflow-hidden">
                <div className="w-full lg:w-2/3 p-8">
                    <div className="text-xl capitalize text-left lg:text-left">
                       Whatsapp Demo Testing
                    </div>
                    <div className="flex items-center my-2 capitalize justify-start lg:justify-start">
                        <FcVip className="mr-2" />
                       Click the buttons to test a whatsapp example
                    </div>
                    <div className="grid grid-cols-1 col-gap-4 row-gap-2 lg:flex text-black ">
                        <div>
                            <button className="font-semibold bg-specialpink hover:bg-iconpink hover:text-white py-2 px-3 lg:mr-1 rounded w-full"     onClick={() => handlePaymentVoucher()}>
                                 Payment Voucher
                            </button>
                        </div>
                        <div>
                            <button className="font-semibold bg-specialgreen hover:bg-icongreen hover:text-white py-2 px-3 rounded w-full" onClick={() => handleNoConvertDownline()}>
                            No Convert Downline
                            </button>
                        </div>
                        <div
                        >
                            <button className="font-semibold bg-specialblue hover:bg-iconblue hover:text-white py-2 px-3 rounded w-full" onClick={() => handleNewMember()}>
                                New Member
                            </button>
                        </div>
                        <div>
                            <button className="font-semibold bg-specialpink hover:bg-iconpink hover:text-white py-2 px-3 lg:mr-1 rounded w-full"     onClick={() => handleWeeklyReport()}>
                               Weekly Report
                            </button>
                        </div>
                        <div>
                            <button className="font-semibold bg-specialgreen hover:bg-icongreen hover:text-white py-2 px-3 rounded w-full" onClick={() => handleRiskyDownline()}>
                            Risky Downline (No Case)
                            </button>
                        </div>
                        <div
                        >
                            <button className="font-semibold bg-specialblue hover:bg-iconblue hover:text-white py-2 px-3 rounded w-full" onClick={() => handleMonthlyReport()}>
                               Monthly Report
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withUser(Dashboard);
