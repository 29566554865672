import React from "react";
import Routes from "../Routes";
import { Link, withRouter } from "react-router-dom";
import { withUser } from "../Contexts/user";
import { FcUndo } from "react-icons/fc";

const Sidebar = withRouter(({ user, location }) => {
    if (user.auth.userId === null) {
        window.location.href = "/login";
    }
    return (
        <div className="bg-black min-h-screen text-white min-w-48 w-2/12 flex-shrink-0 hidden lg:block">
            <div className="hidden lg:block sidebar h-full greyOpaqueBackground ">
        
                <div className="flex justify-center items-center">
                    <div className={` ${ user
                            ? user.auth
                                ? user.auth.ranking
                                    ? user.auth.smcDate
                                        ? "bg-smc-border-image"
                                        : user.auth.pmcDate
                                        ? "bg-pmc-border-image"
                                            : user.auth.emcDate
                                        ? "bg-emc-border-image"
                                            : user.auth.omcDate
                                        ? "bg-omc-border-image"
                                        : "bg-border-image"
                                    : ""
                                : "" : ""}`}>
                        <img
                            src={
                                user.auth.image
                                    ? user.auth.image
                                    : "https://ik.imagekit.io/dnddecpho/dndream/avatar_DmSKRB3ql.png?updatedAt=1719827936337"
                            }
                            alt="Main Content"
                            className="main-content-image"
                        />
                    </div>
                </div>

                {Routes.map(({ Icon, ...route }, i) => {
                    // eslint-disable-next-line
                    if (route.hidden) return;

                    if (user) {
                        if (user.auth) {
                            if (user.auth.ranking) {
                                if (
                                    route.primepath &&
                                    user.auth.ranking.name !== "prime leader"
                                )
                                    // eslint-disable-next-line
                                    return;

                                if (route.pdpath && user.auth.director !== "PD")
                                    // eslint-disable-next-line
                                    return;

                                if (route.sdpath && user.auth.director !== "SD")
                                    // eslint-disable-next-line
                                    return;

                                if (
                                    route.coordinatorpath &&
                                    user.auth.director !== "coordinator"
                                )
                                    // eslint-disable-next-line
                                    return;
                            }
                        }
                    }

                    return (
                        <Link
                            className={`flex font-semibold ${
                                location.pathname === route.path
                                    ? "text-orange-400 font-black underline"
                                    : "text-cool-gray"
                            } hover:underline underline-offset p-2 items-center`}
                            key={i}
                            to={route.path}
                        >
                            <div className="mr-2">
                                <Icon />
                            </div>
                            <div className="text-sm">{route.name}</div>
                        </Link>
                    );
                })}
                <div
                    onClick={user.auth.logout}
                    className="cursor-pointer text-sm flex hover:underline underline-offset p-2 items-center font-semibold"
                >
                    <FcUndo className="mr-2" /> Logout
                </div>
            </div>
        </div>
    );
});

export default withUser(Sidebar);
